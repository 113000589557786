import React from 'react';
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  StyleSheet,
  View,
} from '@react-pdf/renderer';
import logo from '../../assets/img/logo.png';

const styles = StyleSheet.create({
  body: {
    paddingTop: 5,
    paddingBottom: 65,
    paddingHorizontal: 35,
    position: 'relative',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'Oswald',
  },
  subtitleLatir: {
    fontSize: 14,
    fontFamily: 'Oswald',
    color: '#00496B',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 150,
    width: 200,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  datosp: {
    fontSize: 13,
    fontFamily: 'Oswald',
    marginTop: 3,
  },
  datosinner: {
    width: '33.33%',
    marginBottom: 6,
  },
  datos: {
    fontSize: 11,
    color: '#676767',
  },
  wrapper: {
    flexDirection: 'column',
    border: '1px solid #676767',
    padding: 6,
    borderRadius: 5,
    marginBottom: 6,
  },
  wrapperLatir: {
    flexDirection: 'column',
    border: '1px solid #676767',
    borderColor: '#00496B',
    padding: 6,
    borderRadius: 5,
    marginBottom: 6,
  },
  content: {
    flexDirection: 'row',
    marginBottom: 4,
  },
  payed: {
    position: 'absolute',
    top: '90%',
    left: 0,
    color: '#FF0000',
    padding: 8,
    opacity: 0.7,
    fontWeight: 'black',
    textAlign: 'center',
    fontSize: 30,
    width: '170%',
    transform: 'rotate(-45deg)',
    transformOrigin: 'bottom left',
  },
  payedText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

function MyDocument({ data }) {
  // Convertir total a número y obtener cantidad de clientes
  const total = data.total ? Number(data.total) : 0;
  const clientsArray = Array.isArray(data.commission_amount)
    ? data.commission_amount
    : [];
  const clientsCount = clientsArray.length;
  const montoPorCliente = clientsCount > 0 ? (total / clientsCount).toFixed(2) : '0.00';

  return (
    <>
      {data ? (
        <Document title={data.id}>
          <Page size="LETTER" style={styles.body} wrap>
            <Image style={styles.image} src={logo} />
            <Text style={styles.subtitle}>Datos personales</Text>
            <View style={styles.wrapper}>
              <View style={styles.content}>
                <View style={styles.datosinner}>
                  <Text style={styles.datosp}>Empresa:</Text>
                  <Text style={styles.datos}>{data.name}</Text>
                </View>
                <View style={styles.datosinner}>
                  <Text style={styles.datosp}>Fecha:</Text>
                  <Text style={styles.datos}>
                    {data.created_at && new Date(data.created_at).toLocaleDateString()}
                  </Text>
                </View>
              </View>
            </View>
            <Text style={styles.subtitle}>Comisiones</Text>
            <View style={styles.wrapper}>
              <View style={styles.content}>
                <View style={styles.datosinner}>
                  <Text style={styles.datosp}>Total por pagar</Text>
                  <Text style={styles.datos}>${data.total}</Text>
                </View>
                <View style={styles.datosinner}>
                  <Text style={styles.datosp}>Pagado</Text>
                  <Text style={styles.datos}>{data.paid === true ? "Sí" : "No"}</Text>
                </View>
              </View>
            </View>
            {/* Sección adicional: Detalle de clientes y monto a pagar */}
            {clientsCount > 0 && (
              <View style={styles.wrapperLatir}>
                <Text style={styles.subtitleLatir}>Detalle de Clientes</Text>
                {clientsArray.map((client, index) => (
                  <View key={index} style={styles.content}>
                    <Text style={styles.datos}>
                      {client.nuip} - {client.first_name} {client.last_name} - Mesa: {client.table} - Nivel: {client.level} - Monto: ${montoPorCliente}
                    </Text>
                  </View>
                ))}
              </View>
            )}
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              fixed
            />
            {data.paid === true ? (
              <View style={styles.payed}>
                <Text style={styles.payedText}>
                  PAGADO - PAGADO - PAGADO - PAGADO - PAGADO
                </Text>
              </View>
            ) : null}
          </Page>
        </Document>
      ) : (
        "No se pudieron obtener los datos del cliente"
      )}
    </>
  );
}

export default MyDocument;
