import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import CircleLoader from "react-spinners/CircleLoader";
import { connect, useSelector } from "react-redux";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { useState, Fragment, useEffect } from "react";
import Toast from "../../../components/Alert";
import axios from "axios";
import moment from "moment";
import { get_commercial_managers_list } from "../../../redux/actions/commercial-managers/commercial-managers";
import { Combobox, Transition } from "@headlessui/react";
import { IconSelector, IconCheck } from "@tabler/icons-react";

function ClientForm({ get_commercial_managers_list, commercial_managers }) {
	const user = useSelector((state) => state.auth.user);
	const [loading, setLoading] = useState(false);

	const [selected, setSelected] = useState([]);
	const [enterprises2, setEnterprises] = useState([]);
	const [query, setQuery] = useState("");

	useEffect(() => {
		get_commercial_managers_list(true);
	}, [get_commercial_managers_list]);

	useEffect(() => {
		const storedEnterprises = sessionStorage.getItem("enterprises");
		if (storedEnterprises) {
			setEnterprises(JSON.parse(storedEnterprises));
		} else {
			const fetchEnterprises = async () => {
				try {
					const response = await axios.get(
						`${process.env.REACT_APP_API_URL}/enterprise/list/min/`
					);
					setEnterprises(response.data);
					sessionStorage.setItem("enterprises", JSON.stringify(response.data));
					console.log(response.data);
				} catch (error) {
					console.error("Error fetching enterprises:", error);
				}
			};
			fetchEnterprises();
		}
	}, []);
	const [selectedManager, setSelectedManager] = useState(null);
	const [managerQuery, setManagerQuery] = useState("");
	const filteredManagers =
		managerQuery === ""
			? commercial_managers
			: commercial_managers.filter((manager) =>
					`${manager.first_name} ${manager.last_name}`
						.toLowerCase()
						.includes(managerQuery.toLowerCase())
			  );
	const filteredCorpoindustrialCompanies =
		query === ""
			? enterprises2.filter((company) => company.corpoindustrial)
			: enterprises2
					.filter((company) => company.corpoindustrial)
					.filter((company) =>
						company.name_enterprise
							.toLowerCase()
							.replace(/\s+/g, "")
							.includes(query.toLowerCase().replace(/\s+/g, ""))
					);

	const filteredCompanies =
		query === ""
			? enterprises2.filter((company) => !company.corpoindustrial)
			: enterprises2
					.filter((company) => !company.corpoindustrial)
					.filter((company) =>
						company.name_enterprise
							.toLowerCase()
							.replace(/\s+/g, "")
							.includes(query.toLowerCase().replace(/\s+/g, ""))
					);

	const [previewImage, setPreviewImage] = useState();
	const [thumbnailNuip, setThumbnailNuip] = useState(null);
	const [thumbnailServices, setThumbnailServices] = useState(null);
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		document_type: "",
		first_name: "",
		last_name: "",
		gender: "",
		stratum: "",
		nuip: "",
		birth_date: "",
		address: "",
		municipality: "",
		phone: "",
		email: "",
		pathologies: "",
		img_nuip: "",
		img_services: "",
		eps: "",
		prepaid_eps: "",
		enterprises: "",
		table: "",
		corpoindustrial: "",
		marital_status: "",
		commercial_manager: "",
	});

	const [modalData, setModalData] = useState({
		document_type: "",
		first_name: "",
		last_name: "",
		gender: "",
		stratum: "",
		nuip: "",
		birth_date: "",
		address: "",
		municipality: "",
		phone: "",
		email: "",
		pathologies: "",
		img_nuip: "",
		img_services: "",
		eps: "",
		prepaid_eps: "",
		enterprises: "",
		table: "",
		marital_status: "",
		kinship: "",
	});

	const {
		document_type,
		first_name,
		last_name,
		gender,
		stratum,
		nuip,
		birth_date,
		address,
		municipality,
		phone,
		email,
		pathologies,
		eps,
		prepaid_eps,
		enterprises,
		table,
		marital_status,
		corpoindustrial,
		commercial_manager,
	} = formData;

	const fileSelectedHandler = (e) => {
		const file = e.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = (e) => {
			setPreviewImage(reader.result);
		};
		setThumbnailNuip(file);
	};

	const fileSelectedHandlerServices = (e) => {
		const file = e.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = (e) => {
			setPreviewImage(reader.result);
		};
		setThumbnailServices(file);
	};

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		if (nuip) {
			setLoading(true);
			const config = {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `JWT ${localStorage.getItem("access")}`,
					Accept: "application/json",
				},
			};

			const formattedBirthDate = moment(birth_date).format("YYYY-MM-DD");
			const formData = new FormData();
			formData.append("document_type", document_type);
			formData.append("first_name", first_name);
			formData.append("last_name", last_name);
			formData.append("gender", gender);
			formData.append("stratum", stratum);
			formData.append("nuip", nuip);
			formData.append("birth_date", formattedBirthDate);
			formData.append("address", address);
			formData.append("municipality", municipality);
			formData.append("phone", phone);
			formData.append("email", email);
			formData.append("pathologies", pathologies);
			formData.append("user_id", user.id);
			if (thumbnailNuip) {
				formData.append("img_nuip", thumbnailNuip, thumbnailNuip.name);
			} else {
				formData.append("img_nuip", "");
			}
			if (thumbnailServices) {
				formData.append(
					"img_services",
					thumbnailServices,
					thumbnailServices.name
				);
			} else {
				formData.append("img_services", "");
			}
			formData.append("beneficiaries", JSON.stringify([modalData]));
			formData.append("eps", eps);
			formData.append("prepaid_eps", prepaid_eps === "SI" ? "True" : "False");
			formData.append(
				"corpoindustrial",
				corpoindustrial === "SI" ? "True" : "False"
			);

			// Asegúrate de que `selected` tiene el campo `name_enterprise`
			if (selected && selected.name_enterprise) {
				formData.append("enterprise", selected.name_enterprise);
			} else {
				formData.append("enterprise", "");
			}

			formData.append("table", table);
			formData.append("marital_status", marital_status);
			formData.append("commercial_manager", commercial_manager);
			const fetchData = async () => {
				try {
					const res = await axios.post(
						`${process.env.REACT_APP_API_URL}/client/create/`,
						formData,
						config
					);

					if (res.status === 201) {
						setLoading(false);
						setFormData({
							document_type: "",
							first_name: "",
							last_name: "",
							gender: "",
							stratum: "",
							nuip: "",
							birth_date: "",
							address: "",
							municipality: "",
							neighborhood: "",
							phone: "",
							email: "",
							pathologies: "",
							img_nuip: "",
							img_services: "",
							eps: "",
							prepaid_eps: "",
							marital_status: "",
							enterprises: "",
							commercial_manager: "",
						});

						Toast({
							tipo: "success",
							mensaje: "¡Titular Registrado Correctamente!",
						});
						navigate("/clients");
					}
				} catch (error) {
					if (
						error.response &&
						error.response.status === 400 &&
						error.response.data.nuip &&
						error.response.data.nuip.includes(
							"Ya existe Cliente con este nuip."
						)
					) {
						Toast({
							tipo: "error",
							mensaje:
								"Ya existe un titular registrado con este numero de documento.",
						});
					} else {
						Toast({
							tipo: "error",
							mensaje: "Ha ocurrido un error, intenta nuevamente.",
						});
					}

					setLoading(false);
				}
			};
			fetchData();
		} else {
			Toast({
				tipo: "error",
				mensaje: "Por favor ingresa el numero de documento.",
			});
		}
	};

	const handleModalDataChange = (e) => {
		setModalData({ ...modalData, [e.target.name]: e.target.value });
	};

	return (
		<DashboardLayout>
			<Helmet>
				<title>Latir | Agregar cliente</title>
			</Helmet>
			<div className="w-full h-100 shadow-card rounded-lg overscroll-y-auto overflow-y-auto">
				<h1 className="font-bold text-3xl  text-gray-900">
					Registro de titulares.
				</h1>
				<form onSubmit={(e) => onSubmit(e)} className="">
					<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
						Datos personales
					</div>
					<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
						<div className="form-control">
							<label htmlFor="document_type" className="label">
								<span className="label-text">Tipo de documento</span>
							</label>
							<select
								name="document_type"
								value={document_type}
								onChange={(e) => onChange(e)}
								required
								className="select select-bordered w-full"
							>
								<option value="">Selecciona el tipo de documento</option>
								<option value="CC">Cédula de Ciudadanía</option>
								<option value="CE">Cédula de Extranjería</option>
								<option value="PA">Pasaporte</option>
								<option value="TI">Tarjeta de Identidad</option>
								<option value="RC">Registro Civil</option>
								<option value="PE">Permiso Especial de Permanencia</option>
								<option value="PT">Permiso Temporal de Permanencia</option>
							</select>
						</div>

						<div className="form-control">
							<label htmlFor="nuip" className="label">
								<span className="label-text">Cédula de identidad</span>
							</label>
							<input
								type="text"
								name="nuip"
								value={nuip}
								onChange={(e) => onChange(e)}
								required
								minLength={6}
								maxLength={11}
								pattern="[0-9]+"
								className="input input-bordered w-full"
								placeholder="Cedula de identidad"
							/>
						</div>

						<div className="form-control">
							<label htmlFor="first_name" className="label">
								<span className="label-text">Nombres</span>
							</label>
							<input
								type="text"
								name="first_name"
								value={first_name}
								onChange={(e) => onChange(e)}
								required
								className="input input-bordered w-full"
								placeholder="Nombres"
							/>
						</div>

						<div className="form-control">
							<label htmlFor="last_name" className="label">
								<span className="label-text">Apellidos</span>
							</label>
							<input
								type="text"
								name="last_name"
								value={last_name}
								onChange={(e) => onChange(e)}
								required
								className="input input-bordered w-full "
								placeholder="Apellidos"
							/>
						</div>

						<div className="form-control">
							<label htmlFor="gender" className="label">
								<span className="label-text">Género</span>
							</label>
							<select
								name="gender"
								value={gender}
								onChange={(e) => onChange(e)}
								required
								className="select select-bordered w-full"
							>
								<option value="">Selecciona el género</option>
								<option value="M">Masculino</option>
								<option value="F">Femenino</option>
								<option value="O">Otro</option>
							</select>
						</div>

						<div className="form-control">
							<label htmlFor="birth_date" className="label">
								<span className="label-text">Fecha de nacimiento</span>
							</label>
							<input
								type="date"
								name="birth_date"
								value={birth_date}
								onChange={(e) => onChange(e)}
								required
								className="input input-bordered w-full"
								placeholder="Fecha de nacimiento"
								max={new Date().toISOString().split("T")[0]}
							/>
						</div>

						<div className="form-control">
							<label htmlFor="marital_status" className="label">
								<span className="label-text">Estado civil</span>
							</label>
							<select
								name="marital_status"
								value={marital_status}
								onChange={(e) => onChange(e)}
								required
								className="select select-bordered w-full"
							>
								<option value="">Selecciona el estado civil</option>
								<option value="SOLTERO">Soltero</option>
								<option value="CASADO">Casado</option>
								<option value="UNIONLIBRE">Unión libre</option>
								<option value="DIVORCIADO">Divorciado</option>
								<option value="VIUDO">Viudo</option>
								<option value="SEPARADO">Separado</option>
							</select>
						</div>
					</div>

					<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
						Contacto
					</div>
					<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
						<div>
							<label htmlFor="phone" className="label">
								<span className="label-text">Número de teléfono</span>
							</label>
							<input
								type="text"
								name="phone"
								value={phone}
								onChange={(e) => onChange(e)}
								required
								pattern="^3[0-9]{9}$"
								className="input input-bordered w-full"
								placeholder="Numero de teléfono"
							/>
						</div>

						<div>
							<label htmlFor="email" className="label">
								<span className="label-text">Correo electrónico</span>
							</label>
							<input
								type="email"
								name="email"
								value={email}
								onChange={(e) => onChange(e)}
								required
								className="input input-bordered w-full"
								placeholder="Correo Electronico"
							/>
						</div>
					</div>

					<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
						Datos de dirección
					</div>
					<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
						<div className="form-control">
							<label htmlFor="stratum" className="label">
								<span className="label-text">Estrato</span>
							</label>
							<select
								name="stratum"
								value={stratum}
								onChange={(e) => onChange(e)}
								className="select select-bordered w-full"
							>
								<option value="">Selecciona el estrato</option>
								<option value="1">Estrato 1</option>
								<option value="2">Estrato 2</option>
								<option value="3">Estrato 3</option>
								<option value="4">Estrato 4</option>
								<option value="5">Estrato 5</option>
								<option value="6">Estrato 6</option>
							</select>
						</div>

						<div className="form-control">
							<label htmlFor="municipality" className="label">
								<span className="label-text">Municipio</span>
							</label>
							<select
								name="municipality"
								value={municipality}
								onChange={(e) => onChange(e)}
								required
								className="select select-bordered w-full"
							>
								<option value="">Selecciona el municipio</option>
								<option value="AR">Arboledas</option>
								<option value="CA">Cucutilla</option>
								<option value="GR">Gramalote</option>
								<option value="LO">Lourdes</option>
								<option value="SP">Salazar de Las Palmas</option>
								<option value="SA">Santiago</option>
								<option value="VC">Villa Caro</option>
								<option value="CU">Cúcuta</option>
								<option value="EZ">El Zulia</option>
								<option value="LP">Los Patios</option>
								<option value="PS">Puerto Santander</option>
								<option value="SC">San Cayetano</option>
								<option value="VR">Villa del Rosario</option>
								<option value="BU">Bucarasica</option>
								<option value="ET">El Tarra</option>
								<option value="SAR">Sardinata</option>
								<option value="TI">Tibú</option>
								<option value="AB">Ábrego</option>
								<option value="CAC">Cáchira</option>
								<option value="CON">Convención</option>
								<option value="EC">El Carmen</option>
								<option value="HA">Hacarí</option>
								<option value="LE">La Esperanza</option>
								<option value="LPB">La Playa de Belén</option>
								<option value="OC">Ocaña</option>
								<option value="SCA">San Calixto</option>
								<option value="TE">Teorama</option>
								<option value="CAA">Cácota</option>
								<option value="CH">Chitagá</option>
								<option value="MU">Mutiscua</option>
								<option value="PA">Pamplona</option>
								<option value="PAM">Pamplonita</option>
								<option value="SDS">Santo Domingo de Silos</option>
								<option value="BO">Bochalema</option>
								<option value="CHI">Chinácota</option>
								<option value="DU">Durania</option>
								<option value="HER">Herrán</option>
								<option value="LAB">Labateca</option>
								<option value="RAG">Ragonvalia</option>
								<option value="TO">Toledo</option>
							</select>
						</div>

						<div className="form-control col-span-1 md:col-span-2 lg:col-span-3">
							<label htmlFor="label" className="label">
								<span className="label-text">Dirección</span>
							</label>
							<textarea
								name="address"
								value={address}
								onChange={(e) => onChange(e)}
								required
								className="textarea textarea-bordered resize-none"
								placeholder="Direccion"
								maxLength={250}
							></textarea>
						</div>
					</div>

					<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
						Laboral
					</div>
					<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
						<div className="form-control">
							<label htmlFor="corpoindustrial" className="label">
								<span className="label-text">CorpoIndustrial</span>
							</label>
							<div className="flex gap-3">
								<div className="form-control">
									<label className="label cursor-pointer">
										<span className="label-text mr-2">Sí</span>
										<input
											type="radio"
											name="corpoindustrial"
											className="radio checked:bg-[#00496B]"
											value="SI"
											onChange={(e) => onChange(e)}
										/>
									</label>
								</div>
								<div className="form-control">
									<label className="label cursor-pointer">
										<span className="label-text mr-2">No</span>
										<input
											type="radio"
											name="corpoindustrial"
											className="radio checked:bg-[#00496B]"
											value="NO"
											onChange={(e) => onChange(e)}
										/>
									</label>
								</div>
							</div>
						</div>

						<div className="form-control">
							<label htmlFor="enterprises" className="label">
								<span className="label-text">Empresa</span>
							</label>
							<div className="">
								{formData.corpoindustrial === "SI" ? (
									<Combobox value={selected} onChange={setSelected}>
										<div className="relative">
											<div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
												<Combobox.Input
													className="w-full input input-bordered"
													displayValue={(company) => company.name_enterprise}
													onChange={(e) => setQuery(e.target.value)}
													name="enterprises"
												/>
												<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
													<IconSelector
														className="h-5 w-5 text-gray-400"
														aria-hidden="true"
													/>
												</Combobox.Button>
											</div>
											<Transition
												as={Fragment}
												leave="transition ease-in duration-100"
												leaveFrom="opacity-100"
												leaveTo="opacity-0"
												afterLeave={() => setQuery("")}
											>
												<Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
													{filteredCorpoindustrialCompanies.length === 0 &&
													query !== "" ? (
														<div className="relative cursor-default select-none px-4 py-2 text-gray-700">
															No se encontró la empresa.
														</div>
													) : (
														filteredCorpoindustrialCompanies.map((company) => (
															<Combobox.Option
																key={company.id}
																className={({ active }) =>
																	`relative cursor-default select-none py-2 pl-10 pr-4 ${
																		active
																			? "bg-[#0096C4] text-white"
																			: "text-gray-900"
																	}`
																}
																value={company}
															>
																{({ selected, active }) => (
																	<>
																		<span
																			className={`block truncate ${
																				selected ? "font-medium" : "font-normal"
																			}`}
																		>
																			{company.name_enterprise}
																		</span>
																		{selected ? (
																			<span
																				className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
																					active
																						? "text-white"
																						: "text-[#0096C4]"
																				}`}
																			>
																				<IconCheck
																					className="h-5 w-5"
																					aria-hidden="true"
																				/>
																			</span>
																		) : null}
																	</>
																)}
															</Combobox.Option>
														))
													)}
												</Combobox.Options>
											</Transition>
										</div>
									</Combobox>
								) : formData.corpoindustrial === "NO" ? (
									<Combobox value={selected} onChange={setSelected}>
										<div className="relative">
											<div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
												<Combobox.Input
													className="w-full input input-bordered"
													displayValue={(company) => company.name_enterprise}
													onChange={(e) => setQuery(e.target.value)}
													name="enterprises"
												/>
												<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
													<IconSelector
														className="h-5 w-5 text-gray-400"
														aria-hidden="true"
													/>
												</Combobox.Button>
											</div>
											<Transition
												as={Fragment}
												leave="transition ease-in duration-100"
												leaveFrom="opacity-100"
												leaveTo="opacity-0"
												afterLeave={() => setQuery("")}
											>
												<Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
													{filteredCompanies.length === 0 && query !== "" ? (
														<div className="relative cursor-default select-none px-4 py-2 text-gray-700">
															No se encontró la empresa.
														</div>
													) : (
														filteredCompanies.map((company) => (
															<Combobox.Option
																key={company.id}
																className={({ active }) =>
																	`relative cursor-default select-none py-2 pl-10 pr-4 ${
																		active
																			? "bg-[#0096C4] text-white"
																			: "text-gray-900"
																	}`
																}
																value={company}
															>
																{({ selected, active }) => (
																	<>
																		<span
																			className={`block truncate ${
																				selected ? "font-medium" : "font-normal"
																			}`}
																		>
																			{company.name_enterprise}
																		</span>
																		{selected ? (
																			<span
																				className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
																					active
																						? "text-white"
																						: "text-[#0096C4]"
																				}`}
																			>
																				<IconCheck
																					className="h-5 w-5"
																					aria-hidden="true"
																				/>
																			</span>
																		) : null}
																	</>
																)}
															</Combobox.Option>
														))
													)}
												</Combobox.Options>
											</Transition>
										</div>
									</Combobox>
								) : (
									<div className="text-sm">
										Selecciona si la empresa pertenece o no a Corpoindustrial.
									</div>
								)}
							</div>
						</div>
					</div>

					<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
						Salud
					</div>
					<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
						<div className="form-control">
							<label htmlFor="prepaid_eps" className="label">
								<span className="label-text">EPS Prepagada</span>
							</label>
							<div className="flex gap-3">
								<div className="form-control">
									<label className="label cursor-pointer">
										<span className="label-text mr-2">Sí</span>
										<input
											type="radio"
											name="prepaid_eps"
											className="radio checked:bg-[#00496B]"
											value="SI"
											onChange={(e) => onChange(e)}
										/>
									</label>
								</div>
								<div className="form-control">
									<label className="label cursor-pointer">
										<span className="label-text mr-2">No</span>
										<input
											type="radio"
											name="prepaid_eps"
											className="radio checked:bg-[#00496B]"
											value="NO"
											onChange={(e) => onChange(e)}
										/>
									</label>
								</div>
							</div>
						</div>

						<div className="form-control">
							<label htmlFor="eps" className="label">
								<span className="label-text">EPS</span>
							</label>
							<select
								name="eps"
								value={eps}
								onChange={(e) => onChange(e)}
								className="select select-bordered w-full"
							>
								<option value="">Selecciona la EPS</option>
								<option value="ALIANSALUD">ALIANSALUD</option>
								<option value="CAPITAL SALUD">CAPITAL SALUD</option>
								<option value="CAPRESOCA">CAPRESOCA EPS</option>
								<option value="COMPENSAR">COMPENSAR E.P.S.</option>
								<option value="COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA">
									COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL
									DE CARTAGENA
								</option>
								<option value="E.P.S.  FAMISANAR LTDA.">
									E.P.S. FAMISANAR LTDA.{" "}
								</option>
								<option value="E.P.S.  SANITAS S.A.">
									E.P.S. SANITAS S.A.
								</option>
								<option value="EPS SERVICIO OCCIDENTAL DE SALUD S.A.">
									EPS SERVICIO OCCIDENTAL DE SALUD S.A.
								</option>
								<option value="EPS Y MEDICINA PREPAGADA SURAMERICANA S.A">
									EPS Y MEDICINA PREPAGADA SURAMERICANA S.A
								</option>
								<option value="FUNDACIÓN SALUD MIA EPS">
									FUNDACIÓN SALUD MIA EPS
								</option>
								<option value="MALLAMAS">MALLAMAS</option>
								<option value="NUEVA EPS S.A.">NUEVA EPS S.A.</option>
								<option value="SALUD TOTAL S.A.  E.P.S.">
									SALUD TOTAL S.A. E.P.S.
								</option>
								<option value="SALUDVIDA S.A. E.P.S">
									SALUDVIDA S.A. E.P.S
								</option>
								<option value="SAVIA SALUD EPS">SAVIA SALUD EPS</option>
							</select>
						</div>

						<div className="form-control col-span-1 md:col-span-2 lg:col-span-3">
							<label htmlFor="pathologies" className="label">
								<span className="label-text">Patologías</span>
							</label>
							<textarea
								type="text"
								name="pathologies"
								value={pathologies}
								onChange={(e) => onChange(e)}
								className="textarea textarea-bordered resize-none"
								placeholder="Patologias"
								maxLength={250}
							></textarea>
						</div>
					</div>

					<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
						Adjuntos
					</div>
					<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
						<div>
							<label htmlFor="img_nuip" className="label">
								<span className="label-text">
									Imagen del documento de identidad
								</span>
							</label>
							<input
								name="img_nuip"
								type="file"
								accept="image/*"
								onChange={(e) => fileSelectedHandler(e)}
								className="file-input file-input-bordered w-full"
							/>
						</div>

						<div>
							<label htmlFor="img_services" className="label">
								<span className="label-text">
									Imagen del recibo de servicio de luz
								</span>
							</label>
							<input
								name="img_services"
								type="file"
								accept="image/*"
								onChange={(e) => fileSelectedHandlerServices(e)}
								className="file-input file-input-bordered w-full"
							/>
						</div>
						<div>
							{user && user.table.length > 1 ? (
								<>
									<label htmlFor="table" className="label">
										<span className="label-text">Mesa</span>
									</label>
									<select
										name="table"
										value={table}
										onChange={(e) => onChange(e)}
										required
										className="select select-bordered w-full"
									>
										<option value="">Selecciona la mesa</option>
										{user.table.map((item, index) => (
											<option key={index} value={item.tabel}>
												{item.table}
											</option>
										))}
									</select>
								</>
							) : (
								<></>
							)}
						</div>
					</div>

					<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
						Gestor Comercial
					</div>
					<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
						<div className="form-control">
							<label htmlFor="commercial_manager" className="label">
								<span className="label-text">Gestor Comercial</span>
							</label>
							<Combobox
								value={selectedManager}
								onChange={(manager) => {
									setSelectedManager(manager);
									// Actualiza el formData usando el onChange de tu formulario:
									onChange({
										target: { name: "commercial_manager", value: manager.id },
									});
								}}
							>
								<div className="relative">
									<Combobox.Input
										className="w-full input input-bordered"
										displayValue={(manager) =>
											manager
												? `${manager.first_name} ${manager.last_name}`
												: ""
										}
										onChange={(e) => setManagerQuery(e.target.value)}
									/>
									<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
										<IconSelector
											className="h-5 w-5 text-gray-400"
											aria-hidden="true"
										/>
									</Combobox.Button>
									<Transition
										as={Fragment}
										leave="transition ease-in duration-100"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
										afterLeave={() => setManagerQuery("")}
									>
										<Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
											{filteredManagers.length === 0 && managerQuery !== "" ? (
												<div className="relative cursor-default select-none px-4 py-2 text-gray-700">
													No se encontró gestor comercial.
												</div>
											) : (
												filteredManagers.map((manager) => (
													<Combobox.Option
														key={manager.id}
														value={manager}
														className={({ active }) =>
															`relative cursor-default select-none py-2 pl-10 pr-4 ${
																active
																	? "bg-[#0096C4] text-white"
																	: "text-gray-900"
															}`
														}
													>
														{({ selected, active }) => (
															<>
																<span
																	className={`block truncate ${
																		selected ? "font-medium" : "font-normal"
																	}`}
																>
																	{manager.first_name} {manager.last_name}
																</span>
																{selected && (
																	<span
																		className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
																			active ? "text-white" : "text-[#0096C4]"
																		}`}
																	>
																		<IconCheck
																			className="h-5 w-5"
																			aria-hidden="true"
																		/>
																	</span>
																)}
															</>
														)}
													</Combobox.Option>
												))
											)}
										</Combobox.Options>
									</Transition>
								</div>
							</Combobox>
						</div>
					</div>

					<div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-3 mt-5">
						{loading ? (
							<div className="btn border bg-[#0096C4] text-white">
								<CircleLoader loading={loading} size={25} color="#ffffff" />
							</div>
						) : (
							<button
								type="submit"
								className="btn border bg-[#0096C4] text-white"
							>
								Guardar
							</button>
						)}
					</div>
				</form>
			</div>
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	commercial_managers: state.commercial_managers.commercial_managers_list,
});

export default connect(mapStateToProps, { get_commercial_managers_list })(
	ClientForm
);
