import { useEffect, useState, Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import Toast from "../../../components/Alert";
import { get_client } from "../../../redux/actions/client/client";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import ViewModal from "../../../components/modals/ViewModal";
import { IconEye, IconId, IconReceipt2 } from "@tabler/icons-react";
import Loading from "../../../components/Loading";
import { Combobox, Transition } from "@headlessui/react";
import { IconSelector, IconCheck } from "@tabler/icons-react";

function ClientEdit({ get_client, client }) {
	const params = useParams();
	const id = params.id;
	const user = useSelector((state) => state.auth.user);

	const [selected, setSelected] = useState([]);
	const [enterprises2, setEnterprises] = useState([]);
	const [query, setQuery] = useState("");

	useEffect(() => {
		const storedEnterprises = sessionStorage.getItem("enterprises");
		if (storedEnterprises) {
			setEnterprises(JSON.parse(storedEnterprises));
		} else {
			const fetchEnterprises = async () => {
				try {
					const response = await axios.get(
						`${process.env.REACT_APP_API_URL}/enterprise/list/min/`
					);
					setEnterprises(response.data);
					sessionStorage.setItem("enterprises", JSON.stringify(response.data));
				} catch (error) {
					console.error("Error fetching enterprises:", error);
				}
			};
			fetchEnterprises();
		}
	}, []);

	const filteredCorpoindustrialCompanies =
		query === ""
			? enterprises2.filter((company) => company.corpoindustrial)
			: enterprises2
					.filter((company) => company.corpoindustrial)
					.filter((company) =>
						company.name_enterprise
							.toLowerCase()
							.replace(/\s+/g, "")
							.includes(query.toLowerCase().replace(/\s+/g, ""))
					);

	const filteredCompanies =
		query === ""
			? enterprises2.filter((company) => !company.corpoindustrial)
			: enterprises2
					.filter((company) => !company.corpoindustrial)
					.filter((company) =>
						company.name_enterprise
							.toLowerCase()
							.replace(/\s+/g, "")
							.includes(query.toLowerCase().replace(/\s+/g, ""))
					);

	useEffect(() => {
		window.scrollTo(0, 0);
		get_client(id);
	}, [get_client, id]);
	const [previewImage, setPreviewImage] = useState();
	const [updateDocumentType, setUpdateDocumentType] = useState(false);
	const [updateFirstName, setUpdateFirstName] = useState(false);
	const [updateLastName, setUpdateLastName] = useState(false);
	const [updateGender, setUpdateGender] = useState(false);
	const [updateStratum, setUpdateStratum] = useState(false);
	const [updateNuip, setUpdateNuip] = useState(false);
	const [updateBirthDate, setUpdateBirthDate] = useState(false);
	const [updateAddress, setUpdateAddress] = useState(false);
	const [updateMunicipality, setUpdateMunicipality] = useState(false);

	const [updatePhone, setUpdatePhone] = useState(false);
	const [updateEmail, setUpdateEmail] = useState(false);
	const [updatePathologies, setUpdatePathologies] = useState(false);
	const [updateEnterprises, setUpdateEnterprises] = useState(false);
	const [updateEps, setUpdateEps] = useState(false);
	const [updatePrepaidEps, setUpdatePrepaidEps] = useState(false);

	const [updateImgNuip, setUpdateImgNuip] = useState(false);
	const [updateImgServices, setUpdateImgServices] = useState(false);
	const [updateImgPayment, setUpdateImgPayment] = useState(false);
	const [updateClientVip, setUpdateClientVip] = useState(false);
	const [updateCorpoindustrial, setUpdateCorpoindustrial] = useState(false);
	const [updateMaritalStatus, setUpdateMaritalStatus] = useState(false);
	const [updateTable, setUpdateTable] = useState(false);
	const [formData, setFormData] = useState({
		document_type: "",
		first_name: "",
		last_name: "",
		gender: "",
		stratum: "",
		nuip: "",
		birth_date: "",
		address: "",
		municipality: "",
		phone: "",
		email: "",
		pathologies: "",
		enterprises: "",
		eps: "",
		prepaid_eps: "",
		number_queries: "",
		img_nuip: "",
		img_services: "",
		img_payment: "",
		client_vip: "",
		corpoindustrial: "",
		marital_status: "",
		table: "",
	});
	const {
		document_type,
		first_name,
		last_name,
		gender,
		stratum,
		nuip,
		birth_date,
		address,
		municipality,
		phone,
		email,
		pathologies,
		enterprises,
		eps,
		prepaid_eps,
		client_vip,
		corpoindustrial,
		marital_status,
		table,
	} = formData;

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};
	const [loading, setLoading] = useState(false);

	const resetStates = () => {
		setUpdateDocumentType(false);
		setUpdateFirstName(false);
		setUpdateLastName(false);
		setUpdateGender(false);
		setUpdateStratum(false);
		setUpdateNuip(false);
		setUpdateBirthDate(false);
		setUpdateAddress(false);
		setUpdateMunicipality(false);
		setUpdatePhone(false);
		setUpdateEmail(false);
		setUpdatePathologies(false);
		setUpdateEnterprises(false);
		setUpdateEps(false);
		setUpdatePrepaidEps(false);
		setUpdateClientVip(false);
		setUpdateImgNuip(false);
		setUpdateImgServices(false);
		setUpdateImgPayment(false);
		setUpdateCorpoindustrial(false);
		setUpdateMaritalStatus(false);
		setUpdateTable(false);
	};

	const fileSelectedHandler = (e) => {
		const file = e.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = (e) => {
			setPreviewImage(reader.result);
		};
		setUpdateImgNuip(file);
	};

	const fileSelectedHandlerServices = (e) => {
		const file = e.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = (e) => {
			setPreviewImage(reader.result);
		};
		setUpdateImgServices(file);
	};

	const fileSelectedHandlerPayment = (e) => {
		const file = e.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = (e) => {
			setPreviewImage(reader.result);
		};
		setUpdateImgPayment(file);
	};

	const onSubmit = (e) => {
		e.preventDefault();
		const config = {
			headers: {
				Accept: "application/json",
				"Content-Type": "multipart/form-data",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		const formData = new FormData();
		formData.append("document_type", document_type);
		formData.append("first_name", first_name);
		formData.append("last_name", last_name);
		formData.append("gender", gender);
		formData.append("stratum", stratum);
		formData.append("nuip", nuip);
		formData.append("birth_date", birth_date);
		formData.append("address", address);
		formData.append("municipality", municipality);
		formData.append("phone", phone);
		formData.append("email", email);
		formData.append("pathologies", pathologies);
		formData.append("enterprise", selected.name_enterprise);
		formData.append("id", id);
		formData.append("eps", eps);
		if (prepaid_eps === "SI") {
			formData.append("prepaid_eps", true);
		} else if (prepaid_eps === "NO") {
			formData.append("prepaid_eps", false);
		}
		if (corpoindustrial === "SI") {
			formData.append("corpoindustrial", true);
		} else if (corpoindustrial === "NO") {
			formData.append("corpoindustrial", false);
		}
		if (client_vip === "SI") {
			formData.append("client_vip", true);
		} else if (client_vip === "NO") {
			formData.append("client_vip", false);
		}
		if (updateImgNuip) {
			formData.append("img_nuip", updateImgNuip, updateImgNuip.name);
		}
		if (updateImgServices) {
			formData.append(
				"img_services",
				updateImgServices,
				updateImgServices.name
			);
		}
		if (updateImgPayment) {
			formData.append("payment_image", updateImgPayment, updateImgPayment.name);
		}

		formData.append("marital_status", marital_status);
		formData.append("table", table);
		const fetchData = async () => {
			setLoading(true);
			try {
				const res = await axios.put(
					`${process.env.REACT_APP_API_URL}/client/edit/${id}/`,
					formData,
					config
				);
				if (res.status === 200) {
					setFormData({
						document_type: "",
						first_name: "",
						last_name: "",
						gender: "",
						stratum: "",
						nuip: "",
						birth_date: "",
						address: "",
						municipality: "",
						neighborhood: "",
						phone: "",
						email: "",
						pathologies: "",
						enterprises: "",
						eps: "",
						prepaid_eps: "",
						number_queries: "",
						img_nuip: "",
						img_services: "",
						img_payment: "",
						client_vip: "",
						corpoindustrial: "",
						marital_status: "",
						table: "",
					});
					setLoading(false);
					resetStates();
					get_client(id);
					Toast({ tipo: "success", mensaje: res.data.success });
				} else {
					setLoading(false);
					resetStates();
					Toast({ tipo: "error", mensaje: res.data.error });
				}
			} catch (err) {
				console.log(err);
				if (
					err.response &&
					err.response.status === 400 &&
					err.response.data &&
					err.response.data.error
				) {
					if (
						err.response.data.error.includes(
							"Error al Editar el Titular: duplicate key value violates unique constraint"
						)
					) {
						setLoading(false);
						resetStates();
						Toast({
							tipo: "error",
							mensaje:
								"No puedes editar y agregar el numero de documento de otra persona.",
						});
					}
				} else {
					setLoading(false);
					resetStates();
					Toast({ tipo: "error", mensaje: "Error al editar el titular." });
				}
			}
		};
		fetchData();
	};
	return (
		<DashboardLayout>
			{client ? (
				<>
					<Helmet>
						<title>Latir | Editar cliente</title>
					</Helmet>
					<div className="w-full overflow-y-auto rounded-lg h-100 shadow-card overscroll-y-auto">
						<h1 className="text-3xl font-bold text-gray-900">
							Editar titular.
						</h1>

						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Datos personales
						</div>

						<div className="grid grid-cols-1 gap-3 p-3 border border-gray-300 rounded-lg md:grid-cols-2 lg:grid-cols-3">
							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Tipo de documento</span>
								</label>
								{updateDocumentType ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="">
												<select
													name="document_type"
													value={document_type}
													onChange={(e) => onChange(e)}
													required
													className="w-full select select-bordered"
												>
													<option value="">
														Selecciona el tipo de documento
													</option>
													<option value="CC">Cédula de ciudadanía</option>
													<option value="CE">Cédula de extranjería</option>
													<option value="PA">Pasaporte</option>
													<option value="TI">Tarjeta de identidad</option>
													<option value="RC">Registro civil</option>
													<option value="PE">
														Permiso especial de permanencia
													</option>
													<option value="PT">
														Permiso temporal de permanencia
													</option>
												</select>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateDocumentType(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<div className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{client && client.document_type
												? (() => {
														switch (client.document_type) {
															case "CC":
																return "Cédula de Ciudadanía";
															case "CE":
																return "Cédula de Extranjería";
															case "PA":
																return "Pasaporte";
															case "TI":
																return "Tarjeta de Identidad";
															case "RC":
																return "Registro Civil";
															case "PE":
																return "Permiso Especial de Permanencia";
															case "PT":
																return "Permiso Temporal de Permanencia";
															default:
																return "Sin Especificar";
														}
												  })()
												: "Sin Especificar"}
										</div>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateDocumentType(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Número de documento</span>
								</label>
								{updateNuip ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													value={nuip}
													onChange={(e) => onChange(e)}
													name="nuip"
													type="text"
													className="w-full input input-bordered"
													pattern="[0-9]+"
													minLength={6}
													maxLength={11}
													required
												/>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateNuip(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{client.nuip}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateNuip(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="first_name" className="label">
									<span className="label-text">Nombres</span>
								</label>
								{updateFirstName ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													value={first_name}
													onChange={(e) => onChange(e)}
													name="first_name"
													type="text"
													className="w-full input input-bordered"
													required
												/>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateFirstName(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{client.first_name}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateFirstName(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="last_name" className="label">
									<span className="label-text">Apellidos</span>
								</label>
								{updateLastName ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													value={last_name}
													onChange={(e) => onChange(e)}
													name="last_name"
													type="text"
													className="w-full input input-bordered"
													required
												/>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateLastName(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{client.last_name}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateLastName(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="gender" className="label">
									<span className="label-text">Género</span>
								</label>
								{updateGender ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<select
													name="gender"
													value={gender}
													onChange={(e) => onChange(e)}
													required
													className="w-full select select-bordered"
												>
													<option value="">Selecciona el género</option>
													<option value="M">Masculino</option>
													<option value="F">Femenino</option>
													<option value="O">Otro</option>
												</select>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateGender(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{client.gender === "M"
												? "Masculino"
												: client.gender === "F"
												? "Femenino"
												: "Otro"}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateGender(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="birth_date" className="label">
									<span className="label-text">Fecha de nacimiento</span>
								</label>
								{updateBirthDate ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													value={birth_date}
													onChange={(e) => onChange(e)}
													name="birth_date"
													type="date"
													className="w-full input input-bordered"
													required
													max={new Date().toISOString().split("T")[0]}
												/>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateBirthDate(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{client.birth_date}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateBirthDate(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="marital_status" className="label">
									<span className="label-text">Estado civil</span>
								</label>
								{updateMaritalStatus ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<select
													name="marital_status"
													value={marital_status}
													onChange={(e) => onChange(e)}
													required
													className="w-full select select-bordered"
												>
													<option value="">Selecciona el estado civil</option>
													<option value="SOLTERO">Soltero</option>
													<option value="CASADO">Casado</option>
													<option value="UNIÓN LIBRE">Unión libre</option>
													<option value="DIVORCIADO">Divorciado</option>
													<option value="VIUDO">Viudo</option>
													<option value="SEPARADO">Separado</option>
												</select>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateMaritalStatus(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{client.marital_status}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateMaritalStatus(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							{user && user.role === "ENLACE" && (
								<div className="form-control">
									<label htmlFor="client_vip" className="label">
										<span className="label-text">Cliente VIP</span>
									</label>
									{updateClientVip ? (
										<>
											<form onSubmit={(e) => onSubmit(e)} className="">
												<span className="flex-grow">
													<select
														name="client_vip"
														value={client_vip}
														onChange={(e) => onChange(e)}
														required
														className="w-full select select-bordered"
													>
														<option value="">Selecciona el estado</option>
														<option value="SI">SI</option>
														<option value="NO">NO</option>
													</select>
												</span>
												<div className="flex gap-4 mt-3">
													<button
														type="submit"
														className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
													>
														Guardar
													</button>
													<button
														type="button"
														onClick={() => setUpdateClientVip(false)}
														className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
													>
														Cancelar
													</button>
												</div>
											</form>
										</>
									) : (
										<>
											<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
												{client.client_vip === true ? "SI" : "NO"}
											</span>
											<div className="mt-3">
												<button
													type="button"
													onClick={() => setUpdateClientVip(true)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Modificar
												</button>
											</div>
										</>
									)}
								</div>
							)}
						</div>

						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Contacto
						</div>

						<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
							<div className="form-control">
								<label htmlFor="phone" className="label">
									<span className="label-text">Teléfono</span>
								</label>
								{updatePhone ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													value={phone}
													onChange={(e) => onChange(e)}
													name="phone"
													type="text"
													className="input input-bordered w-full"
													pattern="^3[0-9]{9}$"
													required
												/>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdatePhone(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{client.phone}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdatePhone(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="email" className="label">
									<span className="label-text">Correo electrónico</span>
								</label>
								{updateEmail ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													value={email}
													onChange={(e) => onChange(e)}
													name="email"
													type="email"
													className="w-full input input-bordered"
													required
												/>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateEmail(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{client.email}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateEmail(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>
						</div>

						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Datos de dirección
						</div>

						<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
							<div className="form-control">
								<label htmlFor="stratum" className="label">
									<span className="label-text">Estrato</span>
								</label>
								{updateStratum ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<select
													name="stratum"
													value={stratum}
													onChange={(e) => onChange(e)}
													required
													className="w-full select select-bordered"
												>
													<option value="">Selecciona el Estrato</option>
													<option value="1">Estrato 1</option>
													<option value="2">Estrato 2</option>
													<option value="3">Estrato 3</option>
													<option value="4">Estrato 4</option>
													<option value="5">Estrato 5</option>
													<option value="6">Estrato 6</option>
												</select>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateStratum(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{client.stratum}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateStratum(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="municipality" className="label">
									<span className="label-text">Municipio</span>
								</label>
								{updateMunicipality ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<select
													name="municipality"
													value={municipality}
													onChange={(e) => onChange(e)}
													required
													className="w-full select select-bordered"
												>
													<option value="">Selecciona el Municipio</option>
													<option value="AR">Arboledas</option>
													<option value="CA">Cucutilla</option>
													<option value="GR">Gramalote</option>
													<option value="LO">Lourdes</option>
													<option value="SP">Salazar de Las Palmas</option>
													<option value="SA">Santiago</option>
													<option value="VC">Villa Caro</option>
													<option value="CU">Cúcuta</option>
													<option value="EZ">El Zulia</option>
													<option value="LP">Los Patios</option>
													<option value="PS">Puerto Santander</option>
													<option value="SC">San Cayetano</option>
													<option value="VR">Villa del Rosario</option>
													<option value="BU">Bucarasica</option>
													<option value="ET">El Tarra</option>
													<option value="SAR">Sardinata</option>
													<option value="TI">Tibú</option>
													<option value="AB">Ábrego</option>
													<option value="CAC">Cáchira</option>
													<option value="CON">Convención</option>
													<option value="EC">El Carmen</option>
													<option value="HA">Hacarí</option>
													<option value="LE">La Esperanza</option>
													<option value="LPB">La Playa de Belén</option>
													<option value="OC">Ocaña</option>
													<option value="SCA">San Calixto</option>
													<option value="TE">Teorama</option>
													<option value="CAA">Cácota</option>
													<option value="CH">Chitagá</option>
													<option value="MU">Mutiscua</option>
													<option value="PA">Pamplona</option>
													<option value="PAM">Pamplonita</option>
													<option value="SDS">Santo Domingo de Silos</option>
													<option value="BO">Bochalema</option>
													<option value="CHI">Chinácota</option>
													<option value="DU">Durania</option>
													<option value="HER">Herrán</option>
													<option value="LAB">Labateca</option>
													<option value="RAG">Ragonvalia</option>
													<option value="TO">Toledo</option>
												</select>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateMunicipality(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{client && client.municipality
												? (() => {
														switch (client.municipality) {
															case "AR":
																return "Arboledas";
															case "CA":
																return "Cucutilla";
															case "GR":
																return "Gramalote";
															case "LO":
																return "Lourdes";
															case "SP":
																return "Salazar de Las Palmas";
															case "SA":
																return "Santiago";
															case "VC":
																return "Villa Caro";
															case "CU":
																return "Cúcuta";
															case "EZ":
																return "El Zulia";
															case "LP":
																return "Los Patios";
															case "PS":
																return "Puerto Santander";
															case "SC":
																return "San Cayetano";
															case "VR":
																return "Villa del Rosario";
															case "BU":
																return "Bucarasica";
															case "ET":
																return "El Tarra";
															case "SAR":
																return "Sardinata";
															case "TI":
																return "Tibú";
															case "AB":
																return "Ábrego";
															case "CAC":
																return "Cáchira";
															case "CON":
																return "Convención";
															case "EC":
																return "El Carmen";
															case "HA":
																return "Hacarí";
															case "LE":
																return "La Esperanza";
															case "LPB":
																return "La Playa de Belén";
															case "OC":
																return "Ocaña";
															case "SCA":
																return "San Calixto";
															case "TE":
																return "Teorama";
															case "CAA":
																return "Cácota";
															case "CH":
																return "Chitagá";
															case "MU":
																return "Mutiscua";
															case "PA":
																return "Pamplona";
															case "PAM":
																return "Pamplonita";
															case "SDS":
																return "Santo Domingo de Silos";
															case "BO":
																return "Bochalema";
															case "CHI":
																return "Chinácota";
															case "DU":
																return "Durania";
															case "HER":
																return "Herrán";
															case "LAB":
																return "Labateca";
															case "RAG":
																return "Ragonvalia";
															case "TO":
																return "Toledo";

															default:
																return "Sin Especificar";
														}
												  })()
												: "Sin Especificar"}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateMunicipality(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="col-span-1 form-control md:col-span-2 lg:col-span-3">
								<label htmlFor="address" className="label">
									<span className="label-text">Dirección</span>
								</label>
								{updateAddress ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<textarea
													value={address}
													onChange={(e) => onChange(e)}
													name="address"
													type="text"
													className="w-full resize-none textarea textarea-bordered"
													required
													maxLength={250}
												></textarea>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateAddress(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{client.address}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateAddress(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>
						</div>

						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Laboral
						</div>

						<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
							<div className="form-control">
								<label htmlFor="corpoindustrial" className="label">
									<span className="label-text">Corpoindustrial</span>
								</label>
								{updateCorpoindustrial ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<div className="flex gap-3">
												<div className="form-control">
													<label className="cursor-pointer label">
														<span className="mr-2 label-text">Sí</span>
														<input
															type="radio"
															name="corpoindustrial"
															className="radio checked:bg-[#00496B]"
															value="SI"
															onChange={(e) => onChange(e)}
															required
														/>
													</label>
												</div>
												<div className="form-control">
													<label className="cursor-pointer label">
														<span className="mr-2 label-text">No</span>
														<input
															type="radio"
															name="corpoindustrial"
															className="radio checked:bg-[#00496B]"
															value="NO"
															onChange={(e) => onChange(e)}
															required
														/>
													</label>
												</div>
											</div>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateCorpoindustrial(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{client.corpoindustrial === true ? "SI" : "NO"}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateCorpoindustrial(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B]"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>
							<div className="form-control">
								<label htmlFor="enterprises" className="label">
									<span className="label-text">Empresas</span>
								</label>
								{updateEnterprises ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<Combobox value={selected} onChange={setSelected}>
													<div className="relative">
														<div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
															<Combobox.Input
																className="w-full input input-bordered"
																displayValue={(company) =>
																	company.name_enterprise
																}
																onChange={(e) => setQuery(e.target.value)}
																name="enterprises"
															/>
															<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
																<IconSelector
																	className="h-5 w-5 text-gray-400"
																	aria-hidden="true"
																/>
															</Combobox.Button>
														</div>
														<Transition
															as={Fragment}
															leave="transition ease-in duration-100"
															leaveFrom="opacity-100"
															leaveTo="opacity-0"
															afterLeave={() => setQuery("")}
														>
															<Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
																{(client.corpoindustrial
																	? filteredCorpoindustrialCompanies
																	: filteredCompanies
																).length === 0 && query !== "" ? (
																	<div className="relative cursor-default select-none px-4 py-2 text-gray-700">
																		No se encontró la empresa.
																	</div>
																) : (
																	(client.corpoindustrial
																		? filteredCorpoindustrialCompanies
																		: filteredCompanies
																	).map((company) => (
																		<Combobox.Option
																			key={company.id}
																			className={({ active }) =>
																				`relative cursor-default select-none py-2 pl-10 pr-4 ${
																					active
																						? "bg-[#0096C4] text-white"
																						: "text-gray-900"
																				}`
																			}
																			value={company}
																		>
																			{({ selected, active }) => (
																				<>
																					<span
																						className={`block truncate ${
																							selected
																								? "font-medium"
																								: "font-normal"
																						}`}
																					>
																						{company.name_enterprise}
																					</span>
																					{selected ? (
																						<span
																							className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
																								active
																									? "text-white"
																									: "text-[#0096C4]"
																							}`}
																						>
																							<IconCheck
																								className="h-5 w-5"
																								aria-hidden="true"
																							/>
																						</span>
																					) : null}
																				</>
																			)}
																		</Combobox.Option>
																	))
																)}
															</Combobox.Options>
														</Transition>
													</div>
												</Combobox>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B]"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateEnterprises(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B]"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{enterprises2.find(
												(company) => company.id === client.enterprise
											)?.name_enterprise || "Empresa no encontrada"}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateEnterprises(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B]"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>
						</div>

						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Salud
						</div>

						<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
							<div className="form-control">
								<label htmlFor="prepaid_eps" className="label">
									<span className="label-text">EPS Prepagada</span>
								</label>
								{updatePrepaidEps ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<div className="flex gap-3">
												<div className="form-control">
													<label className="cursor-pointer label">
														<span className="mr-2 label-text">Sí</span>
														<input
															type="radio"
															name="prepaid_eps"
															className="radio checked:bg-[#00496B]"
															value="SI"
															onChange={(e) => onChange(e)}
															required
														/>
													</label>
												</div>
												<div className="form-control">
													<label className="cursor-pointer label">
														<span className="mr-2 label-text">No</span>
														<input
															type="radio"
															name="prepaid_eps"
															className="radio checked:bg-[#00496B]"
															value="NO"
															onChange={(e) => onChange(e)}
															required
														/>
													</label>
												</div>
											</div>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdatePrepaidEps(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{client.prepaid_eps ? "Sí" : "No"}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdatePrepaidEps(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="eps" className="label">
									<span className="label-text">EPS</span>
								</label>
								{updateEps ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<select
													name="eps"
													value={eps}
													onChange={(e) => onChange(e)}
													required
													className="w-full select select-bordered"
												>
													<option value="">Selecciona la EPS</option>
													<option value="ALIANSALUD">ALIANSALUD</option>
													<option value="CAPITAL SALUD">CAPITAL SALUD</option>
													<option value="CAPRESOCA">CAPRESOCA EPS</option>
													<option value="COMPENSAR">COMPENSAR E.P.S.</option>
													<option value="COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA">
														COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR
														ORIENTAL DE CARTAGENA
													</option>
													<option value="E.P.S.  FAMISANAR LTDA.">
														E.P.S. FAMISANAR LTDA.{" "}
													</option>
													<option value="E.P.S.  SANITAS S.A.">
														E.P.S. SANITAS S.A.
													</option>
													<option value="EPS SERVICIO OCCIDENTAL DE SALUD S.A.">
														EPS SERVICIO OCCIDENTAL DE SALUD S.A.
													</option>
													<option value="EPS Y MEDICINA PREPAGADA SURAMERICANA S.A">
														EPS Y MEDICINA PREPAGADA SURAMERICANA S.A
													</option>
													<option value="FUNDACIÓN SALUD MIA EPS">
														FUNDACIÓN SALUD MIA EPS
													</option>
													<option value="MALLAMAS">MALLAMAS</option>
													<option value="NUEVA EPS S.A.">NUEVA EPS S.A.</option>
													<option value="SALUD TOTAL S.A.  E.P.S.">
														SALUD TOTAL S.A. E.P.S.
													</option>
													<option value="SALUDVIDA S.A. E.P.S">
														SALUDVIDA S.A. E.P.S
													</option>
													<option value="SAVIA SALUD EPS">
														SAVIA SALUD EPS
													</option>
												</select>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateEps(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{client.eps}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateEps(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="number_queries" className="label">
									<span className="label-text">Numero de Consultas</span>
								</label>
								<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
									{client.number_queries}
								</span>
							</div>

							<div className="col-span-1 form-control md:col-span-2 lg:col-span-3">
								<label htmlFor="pathologies" className="label">
									<span className="label-text">Patologías</span>
								</label>
								{updatePathologies ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<textarea
													type="text"
													name="pathologies"
													value={pathologies}
													onChange={(e) => onChange(e)}
													required
													maxLength={250}
													className="textarea textarea-bordered resize-none w-full"
													placeholder="Patologías"
												></textarea>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdatePathologies(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											{client.pathologies}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdatePathologies(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>
						</div>
						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Imágenes
						</div>
						<div className="grid grid-cols-1 gap-3 p-3 border border-gray-300 rounded-lg md:grid-cols-2 lg:grid-cols-3">
							<div className="form-control">
								<label htmlFor="img_nuip" className="label">
									<span className="label-text">
										Imagen Documento de Identidad
									</span>
								</label>
								{updateImgNuip ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													name="img_nuip"
													type="file"
													accept="image/*"
													required
													onChange={(e) => fileSelectedHandler(e)}
													className="w-full file-input file-input-bordered"
												/>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateImgNuip(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : client.img_nuip ? (
									<>
										<ViewModal
											data={client.img_nuip}
											title={"Ver Documento"}
											icon={<IconId />}
										/>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateImgNuip(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
											>
												Modificar
											</button>
										</div>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											Sin imagen
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateImgNuip(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
											>
												Agregar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="img_services" className="label">
									<span className="label-text">Imagen Servicio Público</span>
								</label>
								{updateImgServices ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													name="img_services"
													type="file"
													accept="image/*"
													required
													onChange={(e) => fileSelectedHandlerServices(e)}
													className="w-full file-input file-input-bordered"
												/>
											</span>
											<div className="flex gap-4 mt-3">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateImgServices(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : client.img_services ? (
									<>
										<ViewModal
											data={client.img_services}
											title={"Ver Recibo"}
											icon={<IconReceipt2 />}
										/>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateImgServices(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
											>
												Modificar
											</button>
										</div>
									</>
								) : (
									<>
										<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
											Sin imagen
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateImgServices(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
											>
												Agregar
											</button>
										</div>
									</>
								)}
							</div>
							{client.token_verified && client.token_verified ? (
								client.validated_payment && client.validated_payment ? (
									<div className="form-control">
										<label htmlFor="img_payment" className="label">
											<span className="label-text">Pago</span>
										</label>
										<ViewModal
											data={client.img_payment}
											title={"Ver Certificado"}
											icon={<IconEye />}
										/>
									</div>
								) : (
									<div className="form-control">
										<label htmlFor="img_payment" className="label">
											<span className="label-text">Pago</span>
										</label>
										{updateImgPayment ? (
											<>
												<form onSubmit={(e) => onSubmit(e)} className="">
													<span className="flex-grow">
														<input
															name="img_payment"
															type="file"
															accept="image/*"
															required
															onChange={(e) => fileSelectedHandlerPayment(e)}
															className="w-full file-input file-input-bordered"
														/>
													</span>
													<div className="flex gap-4 mt-3">
														<button
															type="submit"
															className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
														>
															Guardar
														</button>
														<button
															type="button"
															onClick={() => setUpdateImgPayment(false)}
															className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
														>
															Cancelar
														</button>
													</div>
												</form>
											</>
										) : client.payment_image ? (
											<>
												{client.payment_image &&
												client.payment_image.img_payment === null ? (
													<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
														Sin imagen
													</span>
												) : (
													<ViewModal
														data={client.payment_image.img_payment}
														title={"Ver Certificado"}
														icon={<IconEye />}
													/>
												)}
												<div className="mt-3">
													<button
														type="button"
														onClick={() => setUpdateImgPayment(true)}
														className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
													>
														Modificar
													</button>
												</div>
											</>
										) : (
											<>
												<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
													Sin imagen
												</span>
												<div className="mt-3">
													<button
														type="button"
														onClick={() => setUpdateImgPayment(true)}
														className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
													>
														Agregar
													</button>
												</div>
											</>
										)}
									</div>
								)
							) : (
								// agrega una etiqueta que no ha sido verificado el client aun
								<div className="form-control">
									<label htmlFor="img_payment" className="label">
										<span className="label-text">Pago</span>
									</label>
									<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
										Titular No verificado
									</span>
								</div>
							)}
						</div>
						{user && user.role === "SIAU-REPRESENTANTES" && (
							<>
								<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
									Datos Adicionales
								</div>
								<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
									<div className="form-control">
										<label htmlFor="table" className="label">
											<span className="label-text">Mesa</span>
										</label>
										{updateTable ? (
											<form onSubmit={(e) => onSubmit(e)}>
												<select
													name="table"
													value={table}
													onChange={(e) => onChange(e)}
													required
													className="select select-bordered w-full"
												>
													<option value="">Selecciona la mesa</option>
													{user &&
														user.table &&
														user.table.map((item, index) => (
															<option key={index} value={item.table}>
																{item.table}
															</option>
														))}
												</select>
												<div className="flex gap-4 mt-3">
													<button
														type="submit"
														className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
													>
														Guardar
													</button>
													<button
														type="button"
														onClick={() => setUpdateTable(false)}
														className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
													>
														Cancelar
													</button>
												</div>
											</form>
										) : (
											<>
												<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
													{client.table || "No asignada"}
												</span>
												{user && user.table && user.table.length > 1 && (
													<div className="mt-3">
														<button
															type="button"
															onClick={() => setUpdateTable(true)}
															className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4]"
														>
															Modificar
														</button>
													</div>
												)}
											</>
										)}
									</div>
								</div>
							</>
						)}
					</div>
				</>
			) : (
				<div className="w-full h-full flex justify-center items-center">
					<Loading />
				</div>
			)}
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	client: state.clients.client,
});
export default connect(mapStateToProps, {
	get_client,
})(ClientEdit);
