import React from "react";
import { Link } from "react-router-dom";
import { IconPencil, IconFileDescription } from "@tabler/icons-react";
import CommissionEditModal from "../modals/CommissionEditModal";
import { useSelector } from "react-redux";

function CommissionsPaymentsCardHorizontal({ data, index }) {
  // 1) Parse commission_amount JSON to a JS object
  let commissionAmountDetails = {};
  try {
    commissionAmountDetails = data && JSON.parse(data.commission_amount);
  } catch (e) {
    console.error("Error al parsear los datos de comisión", e);
  }

  const loggedUser = useSelector((state) => state.auth.user);

  // 2) If the Commission is for a Gestor Comercial, `data.user` will be null,
  //    and we’ll look up gestor_comercial inside commissionAmountDetails
  let isGestorComercial = false;
  let gestorData = null;
  if (!data.user && commissionAmountDetails && commissionAmountDetails.gestor_comercial) {
    isGestorComercial = true;
    gestorData = commissionAmountDetails.gestor_comercial; 
    // e.g. { id: "...", name: "jesus 12312 Delgado" }
  }

  return (
    <React.Fragment key={index}>
      <tr className="bg-white border-b">
        {/* --- Conditionally render table cell depending on whether there's a user or a gestor --- */}
        {isGestorComercial ? (
          // CASE A: Commission for a Gestor Comercial
          <td className="p-2 text-xs">
            <div className="w-44">
              <span className="font-bold">Nombres: </span>
              {gestorData && gestorData.name}
            </div>
            <div className="w-44">
              <span className="font-bold">Rol: </span>
              Gestor Comercial
            </div>
          </td>
        ) : data && data.user && 
          (data.user.role === "BANCO" || 
           data.user.role === "COSTOS Y GASTOS" || 
           data.user.role === "IMPUESTOS") ? (
          // CASE B: A user with one of these special roles
          <td className="p-2 text-xs">
            <div className="w-44">
              <span className="font-bold">Rol: </span>
              {data.user.role}
            </div>
          </td>
        ) : (
          // CASE C: Normal user scenario
          <td className="p-2 text-xs">
            <div className="w-44">
              <span className="font-bold">Nombres: </span>
              {data.user && data.user.first_name}
            </div>
            <div className="w-44">
              <span className="font-bold">Apellidos: </span>
              {data.user && data.user.last_name}
            </div>
            <div className="w-44">
              <span className="font-bold">Rol: </span>
              {data.user && data.user.role}
            </div>
          </td>
        )}

        {/* --- Total a pagar --- */}
        <td className="p-2 text-xs">
          <div className="w-56">
            <div className="font-bold">Total a pagar:</div>
            <div>
              <div className="bg-[#0096C4] text-white shadow-md my-1 p-2 rounded-lg">
                {data.total > 0 && (
                  <div className="text-xs">$ {data.total}</div>
                )}
              </div>
            </div>
          </div>
        </td>

        {/* --- Table Number --- */}
        <td className="p-2 text-xs">
				{data.table?
          <div className="w-24">
            <span className="font-bold">Nro: </span>
            <span className="bg-gray-200 px-2 py-1 rounded-full">
              {data.table && data.table.table}
            </span>
          </div>:<span className="bg-gray-200 px-2 py-1 rounded-full">Gestor Comercial</span>}
        </td>

        {/* --- Paid or not --- */}
        <td className="p-2 text-xs">
          <div className="w-24">
            <span className="font-bold">Pagado: </span>
            <span className="text-green-500">{data.paid ? "Si" : "No"}</span>
          </div>
        </td>

        {/* --- Action buttons --- */}
        <td className="p-2 text-xs">
          <div className="flex gap-2">
            {loggedUser && loggedUser.role === "CONTABILIDAD" && !data.paid && (
              <CommissionEditModal id={data.id} isEnterprise={false} />
            )}
            <div className="tooltip flex" data-tip="Generar Reporte">
              <Link
                className="p-1 bg-teal-400 text-white rounded-full"
                to={`/commissions-report/users/${data.id}`}
                target="_blank"
              >
                <IconFileDescription />
              </Link>
            </div>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
}

export default CommissionsPaymentsCardHorizontal;
