import { CircleLoader } from "react-spinners";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import Toast from "../../../components/Alert";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
function ComercialManagerCreate() {
	const [loading, setLoading] = useState(false);
const navigate = useNavigate();
	const [formData, setFormData] = useState({
		nuip: "",
		first_name: "",
		last_name: "",
		phone: "",
		email: "",
	});

	const { nuip, first_name, last_name, phone, email } = formData;

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const config = {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `JWT ${localStorage.getItem("access")}`,
				Accept: "application/json",
			},
		};

		const formData = new FormData();

		formData.append("nuip", nuip);
		formData.append("first_name", first_name);
		formData.append("last_name", last_name);
		formData.append("phone", phone);
		formData.append("email", email);

		const fetcData = async () => {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/commercial-manager/create/`,
					formData,
					config
				);
				if (res.status === 201) {
					setLoading(false);
					setFormData({
						nuip: "",
						first_name: "",
						last_name: "",
						phone: "",
						email: "",
					});
					Toast({
						tipo: "success",
						mensaje: "¡Titular Registrado Correctamente!",
					});
					navigate("/commercial-managers");
				}
			} catch (err) {
				setLoading(false);
				Toast({ type: "error", message: "Error al crear el gestor comercial" });
			}
		};
		fetcData();
	};

	return (
		<DashboardLayout>
			<Helmet>
				<title>Latir | Agregar Gestor Comercial</title>
			</Helmet>
			<div className="w-full h-100 shadow-card rounded-lg overscroll-y-auto overflow-y-auto">
				<h1 className="font-bold text-3xl  text-gray-900">
					Registro de Gestores Comerciales.
				</h1>
				<form onSubmit={(e) => onSubmit(e)} className="">
					<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
						Datos personales
					</div>
					<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
						<div className="form-control">
							<label htmlFor="nuip" className="label">
								<span className="label-text">Cédula de identidad</span>
							</label>
							<input
								type="text"
								name="nuip"
								value={nuip}
								onChange={(e) => onChange(e)}
								required
								minLength={6}
								maxLength={11}
								pattern="[0-9]+"
								className="input input-bordered w-full"
								placeholder="Cedula de identidad"
							/>
						</div>

						<div className="form-control">
							<label htmlFor="first_name" className="label">
								<span className="label-text">Nombres</span>
							</label>
							<input
								type="text"
								name="first_name"
								value={first_name}
								onChange={(e) => onChange(e)}
								required
								className="input input-bordered w-full"
								placeholder="Nombres"
							/>
						</div>

						<div className="form-control">
							<label htmlFor="last_name" className="label">
								<span className="label-text">Apellidos</span>
							</label>
							<input
								type="text"
								name="last_name"
								value={last_name}
								onChange={(e) => onChange(e)}
								required
								className="input input-bordered w-full "
								placeholder="Apellidos"
							/>
						</div>
					</div>
					<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
						Contacto
					</div>
					<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
						<div>
							<label htmlFor="phone" className="label">
								<span className="label-text">Número de teléfono</span>
							</label>
							<input
								type="text"
								name="phone"
								value={phone}
								onChange={(e) => onChange(e)}
								required
								pattern="^3[0-9]{9}$"
								className="input input-bordered w-full"
								placeholder="Numero de teléfono"
							/>
						</div>

						<div>
							<label htmlFor="email" className="label">
								<span className="label-text">Correo electrónico</span>
							</label>
							<input
								type="email"
								name="email"
								value={email}
								onChange={(e) => onChange(e)}
								required
								className="input input-bordered w-full"
								placeholder="Correo Electronico"
							/>
						</div>
					</div>
					<div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-3 mt-5">
						{loading ? (
							<div className="btn border bg-[#0096C4] text-white">
								<CircleLoader loading={loading} size={25} color="#ffffff" />
							</div>
						) : (
							<button
								type="submit"
								className="btn border bg-[#0096C4] text-white"
							>
								Guardar
							</button>
						)}
					</div>
				</form>
			</div>
		</DashboardLayout>
	);
}

export default ComercialManagerCreate;
