import { Helmet } from "react-helmet-async";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { useState } from "react";
import Toast from "../../../components/Alert";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function CommissionsForm() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    amount: "",
    choices: "",
  });
  const navigate = useNavigate();
  const { amount, choices } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    const data = new FormData();
    data.append("amount", amount);
    data.append("choices", choices);

    const fetchData = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/commissions-enterprise/list/`,
          data,
          config
        );
        if (res.status === 201) {
          setLoading(false);
          setFormData({
            amount: "",
            choices: "",
          });
          Toast({
            tipo: "success",
            mensaje:
              "¡Monto de Comision Registrado Correctamente!",
          });
          navigate("/commissions/enterprises");
        }
      } catch (error) {
        setLoading(false);
        if (
          error.response &&
          error.response.status === 400 &&
          error.response.data.non_field_errors.includes("Error.")
        ) {
          Toast({
            tipo: "error",
            mensaje: `¡Ya cargaste el amount para la empresa ${choices}!`,
          });
        }
      }
    };
    fetchData();
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>Latir | Registro de Comisiones</title>
      </Helmet>
      <div className="w-full h-100 shadow-card rounded-lg overscroll-y-auto overflow-y-auto">
        <h1 className="font-bold text-3xl text-gray-900">
          Registro de Comisiones.
        </h1>
        <form onSubmit={onSubmit} className="">
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
            Comisiones para empresas
          </div>
          <div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
            <div className="form-control">
              <label htmlFor="amount" className="label">
                <span className="label-text">Monto</span>
              </label>
              <input 
                type="number"
                className="input input-bordered"
                name="amount"
                onChange={onChange}
              />
            </div>
            <div className="form-control">
              <label htmlFor="choices" className="label">
                <span className="label-text">Empresas</span>
              </label>
              <select
                className="input input-bordered"
                name="choices"
                onChange={onChange}
              >
                <option value="">Selecciona una empresa</option>
                <option value="COORSERPACK">COORSERPACK</option>
								<option value="CULM">CULM</option>
								<option value="FARMACULM">FARMACULM</option>
              </select>
            </div>
          </div>
          <div className="mt-4">
            <button type="submit" className="btn btn-primary">
              Registrar Comisión
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}

export default CommissionsForm;
