import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { get_search } from "../../../redux/actions/search/search";
import bannerImg from "../../../assets/img/banner.png";
import bannerStandard from "../../../assets/img/banner-standard.png";
import bannerPlus from "../../../assets/img/banner-plus.png";
import bannerPremium from "../../../assets/img/banner-premium.png";
import bannerPlatinum from "../../../assets/img/banner-platinum.png";
import bannerVIP from "../../../assets/img/banner-vip.png";
import bannerStandardVIP from "../../../assets/img/banner-standard-vip.png";
import bannerPlusVIP from "../../../assets/img/banner-plus-vip.png";
import bannerPremiumVIP from "../../../assets/img/banner-premium-vip.png";
import bannerPlatinumVIP from "../../../assets/img/banner-platinum-vip.png";
import planStandard from "../../../assets/img/latir-medal-standard.png";
import planPlus from "../../../assets/img/latir-medal-plus.png";
import planPremium from "../../../assets/img/latir-medal-premium.png";
import planPlatinum from "../../../assets/img/latir-medal-platinum.png";
import planStandardVIP from "../../../assets/img/latir-medal-standard-vip.png";
import planPlusVIP from "../../../assets/img/latir-medal-plus-vip.png";
import planPremiumVIP from "../../../assets/img/latir-medal-premium-vip.png";
import planPlatinumVIP from "../../../assets/img/latir-medal-platinum-vip.png";
import axios from "axios";
import moment from "moment";
import Toast from "../../../components/Alert";
import { Dialog, Transition } from "@headlessui/react";
import SearchModal from "../../../components/modals/SearchModal";
import BeneficiaryModal2 from "../../../components/modals/BeneficiaryModal2";
import { IconFileDescription, IconTrash } from "@tabler/icons-react";
function Search({ get_search, search, error }) {
	const user = useSelector((state) => state.auth.user);
	const [nuip, setNuip] = useState("");
	const [updateDiscountCulm, setUpdateDiscountCulm] = useState(false);
	const [loading, setLoading] = useState(false);

	const [showDiscountFields, setShowDiscountFields] = useState(false);

	const [showDiscounts, setShowDiscounts] = useState(false);
	const handleInputChange = (e) => {
		setNuip(e.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		get_search(nuip);
	};

	useEffect(() => {
		if (showDiscounts) {
			get_search(nuip);
			setShowDiscounts(false);
		}
	}, [showDiscounts]);
	const [formData, setFormData] = useState({
		number_queries: "",
		type: "",
		value: "",
		discount: "",
		invoice_code: "",
	});

	const { number_queries, type, value, discount, invoice_code } = formData;

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = (e, id, nuip) => {
		e.preventDefault();
		if (number_queries < 0) {
			Toast({
				tipo: "error",
				mensaje: "El número de consultas no puede ser negativo.",
			});
			return;
		} else if (number_queries > 1) {
			Toast({
				tipo: "error",
				mensaje: "El número de consultas no puede ser mayor a 1.",
			});
			return;
		}
		const config = {
			headers: {
				Accept: "application/json",
				"Content-Type": "multipart/form-data",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		const formData = new FormData();
		formData.append("number_queries", number_queries);
		formData.append("id", id);
		const fetchData = async () => {
			setLoading(true);
			try {
				const res = await axios.put(
					`${process.env.REACT_APP_API_URL}/client/edit/${id}/`,
					formData,
					config
				);
				if (res.status === 200) {
					setFormData({
						number_queries: "",
					});
					setLoading(false);
					get_search(nuip);
					Toast({ tipo: "success", mensaje: res.data.success });
				} else {
					Toast({ tipo: "error", mensaje: res.data.error });
				}
			} catch (err) {
				Toast({ tipo: "error", mensaje: "Error al editar el pago" });
			}
		};
		fetchData();
	};

	const renderReferralTree = (referrals, level) => {
		if (!referrals || referrals.length === 0) {
			return null;
		}
		const paddingLeftClasses = {
			1: "pl-4",
			2: "pl-8",
			3: "pl-12",
			4: "pl-16",
			5: "pl-20",
			6: "pl-24",
			7: "pl-28",
			8: "pl-32",
			9: "pl-36",
			10: "pl-40",
			11: "pl-44",
			12: "pl-48",
			13: "pl-52",
			14: "pl-56",
		};

		const paddingClass = paddingLeftClasses[level] || "pl-4";

		return (
			<ul className={`mt-1 text-sm text-gray-900 list-disc ${paddingClass}`}>
				{referrals.map((referral) => (
					<li key={referral.nuip} className="py-0.5">
						{referral.first_name} {referral.last_name} - {referral.nuip}
						{referral.referral_tree &&
							referral.referral_tree.length > 0 &&
							renderReferralTree(referral.referral_tree, level + 1)}
					</li>
				))}
			</ul>
		);
	};

	const [modalOpen, setModalOpen] = useState(false);
	const [secondModalOpen, setSecondModalOpen] = useState(false);
	const [modalData, setModalData] = useState({
		type: "",
		nuip: "",
		invoice_code: "",
	});

	const handleConsumeQuery = (type, nuip, holderNuip = null) => {
		setModalData({ type, nuip, holderNuip, invoice_code: "" });
		setModalOpen(true);
	};

	const confirmConsumeQuery = () => {
		if (!modalData.invoice_code) {
			Toast({
				tipo: "error",
				mensaje: "Por favor, ingrese el número de factura",
			});
			return;
		}
		setModalOpen(false);
		setSecondModalOpen(true);
	};

	const confirmConsumeQueryFinal = () => {
		const data = {
			nuip: modalData.nuip,
			type: modalData.type,
			invoice_code: modalData.invoice_code,
		};

		axios
			.post(`${process.env.REACT_APP_API_URL}/search/consume_query/`, data, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `JWT ${localStorage.getItem("access")}`,
				},
			})
			.then((response) => {
				Toast({ tipo: "success", mensaje: response.data.success });
				setSecondModalOpen(false);

				const searchNuip =
					modalData.type === "beneficiary" && modalData.holderNuip
						? modalData.holderNuip
						: modalData.nuip;

				get_search(searchNuip);
			})
			.catch((error) => {
				const errorMsg =
					error.response?.data?.error || "Error al consumir la consulta";
				Toast({ tipo: "error", mensaje: errorMsg });
			});
	};

	const refreshData = () => {
		get_search(nuip);
	};

	return (
		<DashboardLayout>
			<div className="border-b border-gray-200 bg-white py-5 sm:px-6">
				<div className="flex flex-col flex-wrap lg:flex-row lg:items-center justify-between sm:flex-nowrap">
					<div className="">
						<h3 className="text-2xl font-medium leading-6 text-gray-900">
							Buscar Titulares
						</h3>
					</div>
					<div className="flex flex-col lg:flex-row lg:items-center gap-4 py-2 lg:py-0">
						<div className="flex justify-between gap-4">
							<div className=""></div>
							<div className=""></div>
						</div>
						<div className="">
							<div className="flex items-center md:max-w-3xl md:mx-auto lg:max-w-none">
								<form onSubmit={handleSubmit} className="w-full">
									<label htmlFor="search" className="sr-only">
										Buscar
									</label>
									<div className="relative">
										<button
											type="submit"
											className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={1.5}
												stroke="currentColor"
												className="w-6 h-6"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
												/>
											</svg>
										</button>
										<input
											id="nuip"
											name="nuip"
											required
											value={nuip}
											onChange={handleInputChange}
											className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											placeholder="Buscar"
											type="search"
										/>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			{error && error ? (
				<p className="text-red-500 text-center mt-10">{error}</p>
			) : search && search.length > 0 ? (
				<div>
					{search.map((item) => (
						<div
							key={item.id}
							className="container px-4 mb-5 max-w-3xl mx-auto mt-6 lg:mt-10"
						>
							<div className="bg-white shadow-md shadow-[#bde3f3] rounded-lg mb-5 overflow-hidden">
								<div className="banner-profile">
									{item.client && item.client.client_vip === true ? (
										<>
											{item.client && item.client.plans
												? (() => {
														switch (item.client.plans) {
															case "CLASSIC":
																return (
																	<img
																		src={bannerStandardVIP}
																		className="w-full"
																		alt="banner"
																	/>
																);
															case "PLUS":
																return (
																	<img
																		src={bannerPlusVIP}
																		className="w-full"
																		alt="banner"
																	/>
																);
															case "PREMIUM":
																return (
																	<img
																		src={bannerPremiumVIP}
																		className="w-full"
																		alt="banner"
																	/>
																);
															case "PLATINUM":
																return (
																	<img
																		src={bannerPlatinumVIP}
																		className="w-full"
																		alt="banner"
																	/>
																);
															default:
																return (
																	<>
																		<img
																			src={bannerVIP}
																			className="w-full"
																			alt="banner"
																		/>
																	</>
																);
														}
												  })()
												: "Sin Especificar"}
										</>
									) : item.client.plans ? (
										(() => {
											switch (item.client.plans) {
												case "CLASSIC":
													return (
														<img
															src={bannerStandard}
															className="w-full"
															alt="banner"
														/>
													);
												case "PLUS":
													return (
														<img
															src={bannerPlus}
															className="w-full"
															alt="banner"
														/>
													);
												case "PREMIUM":
													return (
														<img
															src={bannerPremium}
															className="w-full"
															alt="banner"
														/>
													);
												case "PLATINUM":
													return (
														<img
															src={bannerPlatinum}
															className="w-full"
															alt="banner"
														/>
													);
												default:
													return (
														<img
															src={bannerImg}
															className="w-full"
															alt="banner"
														/>
													);
											}
										})()
									) : (
										<img src={bannerImg} className="w-full" alt="banner" />
									)}
								</div>
								<div className="flex flex-col items-center justify-center p-4">
									<div className="flex justify-between w-full">
										<div className="w-full profile-client">
											<div>
												<h2 className="font-bold text-lg text-[#00496B] uppercase">
													{item.client.last_name}, {item.client.first_name}
												</h2>
											</div>
											<div className="flex justify-between">
												<div>
													<div>
														<span className="text-sm">
															<span className="font-bold text-[#00496B]">
																Documento:
															</span>{" "}
															{item.client.document_type}-{item.client.nuip}
														</span>
													</div>
													<div>
														<span className="text-sm">
															<span className="font-bold text-[#00496B]">
																Género:{" "}
															</span>
															{item.client && item.client.gender
																? (() => {
																		switch (item.client.gender) {
																			case "M":
																				return (
																					<span className="font-normal">
																						Masculino
																					</span>
																				);
																			case "F":
																				return (
																					<span className="font-normal">
																						Femenino
																					</span>
																				);
																			default:
																				return (
																					<span className="font-normal">
																						Otro
																					</span>
																				);
																		}
																  })()
																: "Sin Especificar"}
														</span>
													</div>
												</div>
												<div className="">
													<div>
														<span className="font-bold text-sm text-[#00496B]">
															Estrato:{" "}
														</span>
														<span className="text-sm">
															{item.client.stratum}
														</span>
													</div>
													<div>
														<span className="font-bold text-sm text-[#00496B]">
															Verificado:{" "}
														</span>
														<span className="text-sm">
															{item.client.token_verified ? "Si" : "No"}
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{item.client.memberships &&
							item.client.memberships.length >= 1 &&
							item.client.status &&
							item.client.active ? (
								<>
									<div className="grid grid-cols-1 gap-4 mb-5 md:grid-cols-2">
										<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg">
											<div>
												<span className="font-bold text-[#00496B]">
													Datos adicionales:
												</span>
											</div>
											<div className="flex flex-col justify-center">
												{item.client.memberships.map((membership, index) => (
													<div
														key={index}
														className="flex flex-col justify-center"
													>
														<div>
															<span className="font-bold text-[#00496B]">
																F. Afiliación:
															</span>{" "}
															{membership.membership_day
																? moment(membership.membership_day).format(
																		"DD/MM/YYYY"
																  )
																: "-"}
														</div>
														<div>
															<span className="font-bold text-[#00496B]">
																F. Expiración:
															</span>{" "}
															{membership.expiry_date
																? moment(membership.expiry_date).format(
																		"DD/MM/YYYY"
																  )
																: "-"}
														</div>
													</div>
												))}
												<div className="mt-3">
													{item.client.number_queries > 0 &&
													(user?.role === "SIAU-REPRESENTANTES" ||
														user?.role === "SIAU-LATIR-MAX") ? (
														<>
															<div>
																<span className="font-bold text-[#00496B]">
																	Consulta disponibles
																</span>
															</div>
															<div className="px-4 py-3 bg-gray-100 rounded-lg w-full inline-block">
																{item.client.number_queries}
															</div>
															<button
																onClick={() =>
																	handleConsumeQuery("client", item.client.nuip)
																}
																className="bg-[#0096C4] text-white px-4 py-1.5 rounded hover:bg-slate-700 mt-2"
															>
																Consumir Consulta
															</button>
														</>
													) : (
														<span className="font-bold text-[#00496B]">
															Ya consumiste tu consulta gratuita
														</span>
													)}
												</div>
											</div>
										</div>
										<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg grid grid-cols-3 gap-x-4  place-content-center">
											<div className="font-bold text-center text-[#00496B] text-sm col-span-1">
												{item.client && item.client.client_vip === true ? (
													<>
														{item.client && item.client.plans
															? (() => {
																	switch (item.client.plans) {
																		case "CLASSIC":
																			return (
																				<>
																					<div className="flex justify-center">
																						<img
																							src={planStandardVIP}
																							className="w-20"
																							alt="medal standard vip"
																						/>
																					</div>
																					<span className="">STANDARD </span>{" "}
																					<span className="">VIP</span>
																				</>
																			);
																		case "PLUS":
																			return (
																				<>
																					<div className="flex justify-center">
																						<img
																							src={planPlusVIP}
																							className="w-20"
																							alt="medal plus vip"
																						/>
																					</div>
																					<span className="">PLUS </span>{" "}
																					<span className="">VIP</span>
																				</>
																			);
																		case "PREMIUM":
																			return (
																				<>
																					<div className="flex justify-center">
																						<img
																							src={planPremiumVIP}
																							className="w-20"
																							alt="medal premium vip"
																						/>
																					</div>
																					<span className="">PREMIUM </span>{" "}
																					<span className="">VIP</span>
																				</>
																			);
																		case "PLATINUM":
																			return (
																				<>
																					<div className="flex justify-center">
																						<img
																							src={planPlatinumVIP}
																							className="w-20"
																							alt="medal platinum vip"
																						/>
																					</div>
																					<span className="">PLATINUM </span>{" "}
																					<span className="">VIP</span>
																				</>
																			);
																		default:
																			return <></>;
																	}
															  })()
															: "Sin Especificar"}
													</>
												) : (
													<>
														<div className="flex justify-center">
															{item.client && item.client.plans
																? (() => {
																		switch (item.client.plans) {
																			case "CLASSIC":
																				return (
																					<>
																						<img
																							src={planStandard}
																							className="w-20"
																							alt="medal standard"
																						/>
																					</>
																				);
																			case "PLUS":
																				return (
																					<>
																						<img
																							src={planPlus}
																							className="w-20"
																							alt="medal plus"
																						/>
																					</>
																				);
																			case "PREMIUM":
																				return (
																					<>
																						<img
																							src={planPremium}
																							className="w-20"
																							alt="medal premium"
																						/>
																					</>
																				);
																			case "PLATINUM":
																				return (
																					<>
																						<img
																							src={planPlatinum}
																							className="w-20"
																							alt="medal platinum"
																						/>
																					</>
																				);
																			default:
																				return <></>;
																		}
																  })()
																: "Sin Especificar"}
														</div>
														<span className="">Plan </span>{" "}
														{item.client && item.client.plans
															? (() => {
																	switch (item.client.plans) {
																		case "CLASSIC":
																			return <span className="">Standard</span>;
																		case "PLUS":
																			return <span className="">Plus</span>;
																		case "PREMIUM":
																			return <span className="">Premium</span>;
																		case "PLATINUM":
																			return <span className="">Platinum</span>;
																		default:
																			return (
																				<span className="">
																					Sin especificar
																				</span>
																			);
																	}
															  })()
															: "Sin Especificar"}
													</>
												)}
											</div>
											<div className="col-span-2">
												<span className="font-bold text-[#00496B]">
													Beneficios del plan
												</span>
												<div className="flex justify-center">
													{item.client && item.client.plans
														? (() => {
																switch (item.client.plans) {
																	case "CLASSIC":
																		return (
																			<div>
																				<ul className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 list-disc pl-4">
																					<li className="py-0.5 text-xs">
																						50 % descuento en consultas
																						adicionales{" "}
																					</li>
																					<li className="py-0.5 text-xs">
																						⁠10 % descuento en demás servicios
																						de la Culm
																					</li>
																				</ul>
																			</div>
																		);
																	case "PLUS":
																		return (
																			<div>
																				<ul className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 list-disc pl-4">
																					<li className="py-0.5 text-xs">
																						70 % descuento en consultas
																					</li>
																					<li className="py-0.5 text-xs">
																						⁠Hasta 25%. Descuento en demás
																						servicios Culm
																					</li>
																				</ul>
																			</div>
																		);
																	case "PREMIUM":
																		return (
																			<div>
																				<ul className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 list-disc pl-4">
																					<li className="py-0.5 text-xs">
																						70 % descuento en consultas
																					</li>
																					<li className="py-0.5 text-xs">
																						⁠Hasta 25%. Descuento en demás
																						servicios Culm
																					</li>
																				</ul>
																			</div>
																		);
																	case "PLATINUM":
																		return (
																			<div>
																				<ul className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 list-disc pl-4">
																					<li className="py-0.5 text-xs">
																						70 % descuento en consultas
																					</li>
																					<li className="py-0.5 text-xs">
																						⁠Hasta 25%. Descuento en demás
																						servicios Culm
																					</li>
																				</ul>
																			</div>
																		);
																	default:
																		return <>1</>;
																}
														  })()
														: "Sin Especificar"}
												</div>
											</div>
										</div>
									</div>

									<div className="grid grid-cols-1 gap-4 mb-5">
										<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg">
											<div className="flex flex-col items-center mb-4">
												<span className="font-bold text-[#00496B] text-lg">
													Beneficiarios
												</span>
											</div>
											{item.client.beneficiaries &&
											item.client.beneficiaries.length > 0 ? (
												<div
													className={`grid ${
														item.client.beneficiaries.length === 1
															? "grid-cols-1"
															: "grid-cols-1 md:grid-cols-3"
													} gap-4`}
												>
													{item.client.beneficiaries.map((beneficiary) => (
														<div
															key={beneficiary.nuip}
															className="bg-gray-100 p-3 rounded-md shadow-sm"
														>
															<p className="text-md font-bold text-[#00496B]">
																{beneficiary.first_name} {beneficiary.last_name}
															</p>
															<p className="text-sm text-gray-700">
																{beneficiary.nuip}
															</p>
															{(user?.role === "SIAU-REPRESENTANTES" ||
																user?.role === "SIAU-LATIR-MAX") &&
															beneficiary.number_queries > 0 ? (
																<div className="mt-2">
																	<span className="font-bold text-[#00496B]">
																		Consultas disponibles:{" "}
																	</span>
																	<span>{beneficiary.number_queries}</span>
																	<button
																		onClick={() =>
																			handleConsumeQuery(
																				"beneficiary",
																				beneficiary.nuip,
																				item.client.nuip
																			)
																		}
																		className="block bg-[#0096C4] text-white px-4 py-1 mt-2 rounded hover:bg-slate-700"
																	>
																		Consumir Consulta
																	</button>
																</div>
															) : (
																<p className="text-sm text-gray-500 mt-2">
																	Ya consumiste tu consulta gratuita.
																</p>
															)}
														</div>
													))}
												</div>
											) : (
												<p className="text-center text-gray-500 mt-2">
													No hay beneficiarios disponibles.
												</p>
											)}
										</div>
										<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg">
											<div className="flex flex-col items-center mb-4">
												<span className="font-bold text-[#00496B] text-lg">
													Bonos
												</span>
											</div>
											{item.client.bonus_culm > 0 ? (
												<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
													{item.client.bonus_culm > 0 && (
														<div className="bg-gray-100 p-3 rounded-md shadow-sm">
															<p className="text-md font-bold text-[#00496B]">
																Culm
															</p>
															<p className="text-sm text-gray-700">
																{item.client.bonus_culm}
															</p>
														</div>
													)}

												</div>
											) : (
												<p className="text-center text-gray-500 mt-2">
													No hay bonos disponibles.
												</p>
											)}
										</div>
									</div>
									
								{
									item.client.referred_by && (
										<div className="bg-white shadow-md shadow-[#bde3f3] p-4 mb-5 rounded-lg">
										<div className="flex flex-col items-center mb-4">
											<span className="font-bold text-[#00496B] text-lg">
												Eres Referido de
											</span>

											{item.client.referred_by.first_name || ""}{" "} {item.client.referred_by.last_name || ""} - {item.client.referred_by.nuip || ""}
										</div>
									</div>
									)
								}
									<div className="bg-white shadow-md shadow-[#bde3f3] p-4 mb-5 rounded-lg">
										<div className="flex flex-col items-center mb-4">
											<span className="font-bold text-[#00496B] text-lg">
												Tus Referidos
											</span>
										</div>
										{item.client.referral_tree &&
										item.client.referral_tree.length > 0 ? (
											<div className="grid grid-cols-2 gap-6">
												{item.client.referral_tree.map((referral) => (
													<div key={referral.nuip} className="referral-column">
														<p className="py-0.5 font-bold">
															{referral.first_name} {referral.last_name} -{" "}
															{referral.nuip}
														</p>

														{renderReferralTree(referral.referral_tree, 1)}
													</div>
												))}
											</div>
										) : (
											<p className="text-center text-gray-500 mt-2">
												No hay referidos disponibles.
											</p>
										)}
									</div>

									{/* <div className="bg-white shadow-md shadow-[#bde3f3] p-4 mb-5 rounded-lg">
										<div className="flex flex-col items-center">
											<button
												onClick={() => {
													setShowDiscountFields(true);
												}}
												className="btn btn-sm border bg-white border-[#0096C4] text-[#0096C4] hover:bg-[#0096C4] hover:text-white"
											>
												Agregar Descuento
											</button>
										</div>
									</div> */}

									{/* {showDiscountFields && (
										<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg">
											<form
												onSubmit={(e) => onSubmitDiscount(e, item.client.id)}
											>
												<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
													<div>
														<span className="font-bold text-[#00496B]">
															Tipo
														</span>
													</div>
													<input
														type="text"
														name="type"
														value={type}
														onChange={(e) => onChange(e)}
														className="input input-bordered w-full"
													/>

													<div>
														<span className="font-bold text-[#00496B]">
															Valor
														</span>
													</div>
													<input
														type="number"
														name="value"
														value={value}
														onChange={(e) => onChange(e)}
														className="input input-bordered w-full"
													/>

													<div>
														<span className="font-bold text-[#00496B]">
															Descuento
														</span>
													</div>
													<input
														type="number"
														name="discount"
														value={discount}
														onChange={(e) => onChange(e)}
														className="input input-bordered w-full"
													/>

													<div>
														<span className="font-bold text-[#00496B]">
															Numero de Factura
														</span>
													</div>
													<input
														type="text"
														name="invoice_code"
														value={invoice_code}
														onChange={(e) => onChange(e)}
														className="input input-bordered w-full" 
													/>
												</div>
												<div className="flex justify-end gap-4 mt-4">
													<button
														type="submit"
														className="btn btn-sm border border-[#0096C4] text-[#0096C4] hover:bg-[#0096C4] hover:text-white"
													>
														Enviar
													</button>
													<button
														onClick={() => {
															setShowDiscountFields(false);
														}}
														className="btn btn-sm border border-gray-300 text-gray-700 hover:bg-gray-300 hover:text-white"
													>
														Cerrar
													</button>
												</div>
											</form>
										</div>
									)}

									<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg">
										<span className="font-bold text-[#00496B]">
											Descuentos Consumidos
										</span>
										<div className="flex flex-col justify-center">
											{item.discounts && (
												<div className="overflow-x-auto">
													<table className="table table-xs">
														<thead>
															<tr>
																<th className="text-center">Tipo</th>
																<th className="text-center">Valor</th>
																<th className="text-center">Descuento</th>
																<th className="text-center">
																	Número de Factura
																</th>
																<th className="text-center">Acciones</th>
															</tr>
														</thead>
														<tbody>
															{item.discounts.map((discount, index) => (
																<tr key={index}>
																	<td className="text-center">
																		{discount.type}
																	</td>
																	<td className="text-center">
																		${discount.value}
																	</td>
																	<td className="text-center">
																		${discount.discount}
																	</td>
																	<td className="text-center">
																		{discount.invoice_code}
																	</td>
																	<td className="p-2 text-xs">
																		<div className="flex gap-2 justify-center">
																			<div
																				className="flex tooltip"
																				data-tip="Editar"
																			>
																				<SearchModal
																					discounts={discount}
																					onClose={handleModalClose}
																				/>
																			</div>
																			<div
																				className="tooltip flex"
																				data-tip="Generar Reporte"
																			>
																				<a
																					className="p-1 bg-teal-400 text-white rounded-full"
																					href="#"
																				>
																					<IconFileDescription />
																				</a>
																			</div>
																		</div>
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												</div>
											)}
										</div>
									</div> */}
								</>
							) : item.client.active === false &&
							  item.client.status === false ? (
								<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg">
									<div className="flex justify-center">
										<p className="text-red-500">
											El usuario no se encuentra activo
										</p>
									</div>
								</div>
							) : (
								<div className="p-4">
									<p className="text-sm">
										Titular no tiene membresía y no esta verificado
									</p>
								</div>
							)}
						</div>
					))}
					{modalOpen && (
						<div className="modal modal-open">
							<div className="modal-box">
								<h3 className="font-bold text-lg">
									Confirmar Consumo de Consulta Gratuita
								</h3>
								<p className="py-4">
									¿Estás seguro de que deseas consumir una consulta para este{" "}
									{modalData.type === "client" ? "Titular" : "Beneficiario"} de
									número de documento {modalData.nuip}?
								</p>
								<div className="py-2">
									<label className="block text-sm font-medium text-gray-700">
										Número de Factura
									</label>
									<input
										type="text"
										name="invoice_code"
										value={modalData.invoice_code}
										onChange={(e) =>
											setModalData({
												...modalData,
												invoice_code: e.target.value,
											})
										}
										className="input input-bordered w-full"
										placeholder="Ingrese el número de factura"
									/>
								</div>
								<div className="modal-action">
									<button
										className="bg-[#0096C4] text-white px-4 py-1.5 rounded hover:bg-slate-700"
										onClick={confirmConsumeQuery}
									>
										Confirmar
									</button>
									<button
										className="btn"
										onClick={() => {
											setModalOpen(false);
										}}
									>
										Cancelar
									</button>
								</div>
							</div>
						</div>
					)}
					{secondModalOpen && (
						<div className="modal modal-open">
							<div className="modal-box">
								<h3 className="font-bold text-lg">
									Confirmar Consumo de Consulta Gratuita
								</h3>
								<p className="py-4">
									Esta es la segunda confirmación. ¿Estás seguro de que deseas
									consumir una consulta para este{" "}
									{modalData.type === "client" ? "Titular" : "Beneficiario"} del
									número de documento {modalData.nuip}?
								</p>
								<div className="modal-action">
									<button
										className="bg-[#0096C4] text-white px-4 py-1.5 rounded hover:bg-slate-700"
										onClick={confirmConsumeQueryFinal}
									>
										Confirmar
									</button>
									<button
										className="btn"
										onClick={() => {
											setSecondModalOpen(false);
										}}
									>
										Cancelar
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			) : (
				<p className="m-auto flex justify-center mt-24">
					Para realizar una búsqueda ingresa el número de documento y darle
					click a la lupa o Enter
				</p>
			)}
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	search: state.search.search,
	error: state.search.error,
});

export default connect(mapStateToProps, { get_search })(Search);
