import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import {
  IconHome,
  IconUser,
  IconUsers,
  IconMessageCircleCode,
  IconCash,
  IconReceiptTax,
  IconUsersGroup,
  IconReport,
  IconBriefcase,
  IconCurrencyDollar,
  IconChevronDown
} from '@tabler/icons-react';
import { useSelector } from 'react-redux';

function Sidebar() {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState({});

  const handleDropdownClick = (name) => {
    setIsOpen((prevIsOpen) => ({ ...prevIsOpen, [name]: !prevIsOpen[name] }));
  };

  const navigation = [
    {
      name: "Dashboard",
      href: "/dashboard/",
      icon: IconHome,
      current: location.pathname === "/dashboard/",
      roles: ["SIAU-LATIR-MIN", "SIAU-LATIR-MAX", "CONTABILIDAD", "SIAU-REPRESENTANTES", "ENLACE"],
    },
    {
      name: "Clientes",
      icon: IconUsers,
      roles: ["CONTABILIDAD", "SIAU-REPRESENTANTES", "ENLACE", "SIAU-LATIR-MAX"],
      children: [
        {
          name: "Titular",
          href: "/clients/",
          icon: IconUser,
          current: location.pathname === "/clients/",
          roles: ["CONTABILIDAD", "SIAU-REPRESENTANTES", "ENLACE", "SIAU-LATIR-MAX"],
        },
        {
          name: "Código",
          href: "/shared-token/",
          icon: IconMessageCircleCode,
          current: location.pathname === "/shared-token/",
          roles: ["CONTABILIDAD", "SIAU-REPRESENTANTES", "ENLACE", "SIAU-LATIR-MAX"],
        },
        {
          name: "Pagos",
          href: "/payments/",
          icon: IconCash,
          current: location.pathname === "/payments/",
          roles: ["CONTABILIDAD", "SIAU-REPRESENTANTES", "ENLACE", "SIAU-LATIR-MAX"],
        },
				{
					name: "Consultas Gratuitas",
					href: "/free-consultations/",
					icon: IconCash,
					current: location.pathname === "/free-consultations/",
					roles: ["CONTABILIDAD", "SIAU-REPRESENTANTES", "ENLACE", "SIAU-LATIR-MAX"],
				},
				{
					name: "Gestores Comerciales",
					href: "/commercial-managers/",
					icon: IconCash,
					current: location.pathname === "/commercial-managers/",
					roles: ["CONTABILIDAD", "SIAU-REPRESENTANTES", "ENLACE", "SIAU-LATIR-MAX"],
				}
      ],
    },
    {
      name: "Equipo",
      href: "/team/",
      icon: IconUsersGroup,
      current: location.pathname === "/team/",
      roles: ["CONTABILIDAD", "SIAU-REPRESENTANTES", "ENLACE"],
    },
    {
      name: "Reportes",
      href: "/reports/",
      icon: IconReport,
      current: location.pathname === "/reports/",
      roles: ["CONTABILIDAD", "SIAU-REPRESENTANTES", "ENLACE"],
    },
    {
      name: "Búsqueda de Titulares",
      href: "/search/full/",
      icon: IconUser,
      current: location.pathname === "/search/full/",
      roles: ["SIAU-LATIR-MIN", "SIAU-LATIR-MAX", "CONTABILIDAD", "SIAU-REPRESENTANTES", "ENLACE"],
    },
    {
      name: "Comisiones",
      icon: IconReceiptTax,
      roles: ["CONTABILIDAD", "SIAU-REPRESENTANTES", "ENLACE"],
      children: [
        {
          name: "Usuarios",
          icon: IconUsers,
          roles: ["CONTABILIDAD", "SIAU-REPRESENTANTES", "ENLACE"],
          children: [
            {
              name: "Montos de Comisiones",
              href: "/commissions/users",
              icon: IconCurrencyDollar,
              current: location.pathname === "/commissions/users",
              roles: ["CONTABILIDAD", "SIAU-REPRESENTANTES", "ENLACE"],
            },
            {
              name: "Pagar Comisiones",
              href: "/payment-commissions/users",
              icon: IconCash,
              current: location.pathname === "/payment-commissions/users",
              roles: ["CONTABILIDAD", "SIAU-REPRESENTANTES", "ENLACE"],
            },
          ],
        },
        {
          name: "Empresas",
          icon: IconBriefcase,
          roles: ["CONTABILIDAD", "SIAU-REPRESENTANTES", "ENLACE"],
          children: [
            {
              name: "Registro de Empresas",
              href: "/enterprises",
              icon: IconBriefcase,
              current: location.pathname === "/enterprises",
              roles: ["CONTABILIDAD", "SIAU-REPRESENTANTES", "ENLACE"],
            },
            {
              name: "Montos de Comisiones",
              href: "/commissions/enterprises",
              icon: IconCurrencyDollar,
              current: location.pathname === "/commissions/enterprises",
              roles: ["CONTABILIDAD", "SIAU-REPRESENTANTES", "ENLACE"],
            },
            {
              name: "Pagar Comisiones",
              href: "/payment-commissions/enterprises",
              icon: IconCash,
              current: location.pathname === "/payment-commissions/enterprises",
              roles: ["CONTABILIDAD", "SIAU-REPRESENTANTES", "ENLACE"],
            },
          ],
        },
      ],
    },
  ];

  // Filter navigation items based on user role
  const filterNavigation = (items) =>
    items
      .filter((item) => item.roles.includes(user&&user.role))
      .map((item) => ({
        ...item,
        children: item.children ? filterNavigation(item.children) : null,
      }));

  return (
    <div>
      <ul className="rounded-lg max-w-xs w-full px-0">
        {filterNavigation(navigation).map((item, index) => (
          <li key={index}>
            {item.children ? (
              <div className="submenu">
                <div
                  className="p-2 text-sm font-medium text-gray-500 flex justify-between items-center cursor-pointer"
                  onClick={() => handleDropdownClick(item.name)}
                >
                  <span className="flex items-center">
                    <item.icon className="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
                    {item.name}
                  </span>
                  <IconChevronDown
                    className={`${isOpen[item.name] ? "open" : "close"} submenu-icon ml-2 flex-shrink-0 h-5 w-5`}
                    aria-hidden="true"
                  />
                </div>
                <ul className={isOpen[item.name] ? "open" : "close"}>
                  {item.children.map((child, childIndex) => (
                    <li key={childIndex} className="ml-4">
                      {child.children ? (
                        <div className="submenu">
                          <div
                            className="p-2 text-sm font-medium text-gray-500 flex justify-between items-center cursor-pointer"
                            onClick={() => handleDropdownClick(child.name)}
                          >
                            <span className="flex items-center">
                              <child.icon className="mr-5 flex-shrink-0 h-5 w-5" aria-hidden="true" />
                              {child.name}
                            </span>
                            <IconChevronDown
                              className={`${isOpen[child.name] ? "open" : "close"} submenu-icon ml-2 flex-shrink-0 h-5 w-5`}
                              aria-hidden="true"
                            />
                          </div>
                          <ul className={isOpen[child.name] ? "open" : "close"}>
                            {child.children.map((grandchild, grandchildIndex) => (
                              <li key={grandchildIndex} className="ml-4">
                                <NavLink
                                  to={grandchild.href}
                                  className={`flex items-center p-2 text-sm font-medium rounded-md ${
                                    grandchild.current ? "bg-[#0096C4] text-white" : "text-gray-500"
                                  }`}
                                >
                                  <grandchild.icon className="mr-2 flex-shrink-0 h-4 w-4" aria-hidden="true" />
                                  {grandchild.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        <NavLink
                          to={child.href}
                          className={`flex items-center p-2 text-sm font-medium rounded-md ${
                            child.current ? "bg-[#0096C4] text-white" : "text-gray-500"
                          }`}
                        >
                          <child.icon className="mr-2 flex-shrink-0 h-5 w-5" aria-hidden="true" />
                          {child.name}
                        </NavLink>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <NavLink
                to={item.href}
                className={`flex items-center p-2 text-sm font-medium rounded-md ${
                  item.current ? "bg-[#0096C4] text-white" : "text-gray-500"
                }`}
              >
                <item.icon className="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
                {item.name}
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Sidebar;
