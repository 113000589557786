import React from "react";
import { Link } from "react-router-dom";
import { IconPencil } from "@tabler/icons-react";

function CommissionsCardHorizontal({ data, index }) {
	return (
		<tr className="bg-white border-b">
				<td className="p-2 text-xs">{data.rol}</td>
				<td className="p-2 text-xs">{data.nivel}</td>
				<td className="p-2 text-xs">${data.monto_compensation}</td>
				<td className="p-2 text-xs">
					<div className="flex gap-2">
						<div className="tooltip flex" data-tip="Editar">
							<Link
								to={`/commissions-edit/users/${data.id}`}
								className="p-1 bg-yellow-500 text-white rounded-full"
							>
								<IconPencil />
							</Link>
						</div>
					</div>
				</td>
			</tr>
	);
}
export default CommissionsCardHorizontal;
