import React, { useState } from "react";
import { IconSend, IconX } from "@tabler/icons-react";
import DeleteClient from "../modals/DeleteClient";
import axios from "axios";

function MembershipRenewModal({
  data,
  handleButtonClick,
  isButtonDisabled,
  onSubmitDeleteClient,
  refreshData,
}) {
  const [wantsReferral, setWantsReferral] = useState(false);
  const [referrerNuip, setReferrerNuip] = useState("");
  const [referrerData, setReferrerData] = useState(null);
  const [referrerError, setReferrerError] = useState(null);
  const [isReferrerValidated, setIsReferrerValidated] = useState(false);

  // Local state for controlling the DeleteClient modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const validateReferrerNuip = async () => {
    if (referrerNuip) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };

        const body = {
          nuip_client: referrerNuip,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/client/referred/`,
          body,
          config
        );

        if (response.status === 200) {
          setReferrerData(response.data);
          setIsReferrerValidated(true);
          setReferrerError(null);
					console.log(response.data);
        }
      } catch (error) {
        if (error.response && error.response.data.error) {
          setReferrerError(error.response.data.error);
        } else {
          setReferrerError("Ha ocurrido un error al validar la cédula.");
        }
        setReferrerData(null);
        setIsReferrerValidated(false);
      }
    } else {
      setReferrerError(null);
      setReferrerData(null);
      setIsReferrerValidated(false);
    }
  };

  // Call your membership renewal logic
  const confirmRenew = (e) => {
    // If they chose referral, but haven't validated, block them
    if (wantsReferral && !isReferrerValidated) {
      alert("Debe validar el documento del referente antes de confirmar.");
      return;
    }
    // Actually call the membership renewal
    handleButtonClick(e, data.nuip, referrerNuip);
    refreshData();

    // Close the DaisyUI modal
    document.getElementById("membership_renew_modal").checked = false;
  };

  // “No” => we close the membership renewal modal and open the DeleteClient modal
  const handleDeactivateClick = () => {
    // Close the DaisyUI modal
    document.getElementById("membership_renew_modal").checked = false;
    // Open the DeleteClient modal
    setOpenDeleteModal(true);
  };

  return (
    <div>
      {/* Button that toggles the DaisyUI modal */}
      <div className="flex tooltip" data-tip="Renovar membresía">
        <label
          htmlFor="membership_renew_modal"
          className={`p-1 text-white bg-green-500 rounded-full cursor-pointer ${
            isButtonDisabled ? "disabled" : ""
          }`}
          disabled={isButtonDisabled}
        >
          <IconSend />
        </label>
      </div>

      {/* === The DaisyUI Modal === */}
      <input type="checkbox" id="membership_renew_modal" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box rounded-md">
          <div className="flex justify-between items-center">
            <div className="w-6"></div>
            <h3 className="font-medium text-2xl text-gray-900 text-center">
              Renovar membresía
            </h3>
            <label
              htmlFor="membership_renew_modal"
              className="btn btn-sm btn-circle btn-ghost text-gray-500"
            >
              <IconX size={18} />
            </label>
          </div>

          <div className="my-2">
            <p className="text-xl text-center mb-2 text-gray-500">
              ¿Deseas renovar la membresía de {data.first_name}?
            </p>
            <p className="text-gray-500 text-center">
              Pulsa "Sí" para renovar la membresía o "No" para desactivar al cliente.
            </p>
          </div>

          {/* Option to add a referral */}
          <div className="flex items-center justify-center mt-4">
            <label className="label cursor-pointer">
              <span className="text-gray-500 mr-2">
                ¿Quiere ser referido de alguien más?
              </span>
              <input
                type="checkbox"
                checked={wantsReferral}
                onChange={() => {
                  setWantsReferral(!wantsReferral);
                  setReferrerNuip("");
                  setReferrerData(null);
                  setReferrerError(null);
                  setIsReferrerValidated(false);
                }}
                className="checkbox checkbox-primary"
              />
            </label>
          </div>

          {wantsReferral && (
            <div className="mt-2">
              <label className="text-gray-500">
                Número de Documento del Referente:
              </label>
              <div className="flex">
                <input
                  type="text"
                  value={referrerNuip}
                  onChange={(e) => {
                    setReferrerNuip(e.target.value);
                    setIsReferrerValidated(false);
                    setReferrerError(null);
                    setReferrerData(null);
                  }}
                  className="input input-bordered w-full"
                  placeholder="Ingrese el número de documento"
                />
                <button
                  type="button"
                  onClick={validateReferrerNuip}
                  className="btn ml-2 bg-[#0096C4] text-white"
                >
                  Buscar
                </button>
              </div>
              {referrerError && (
                <p className="text-red-500 text-sm mt-1">{referrerError}</p>
              )}
              {referrerData && (
                <div className="mt-2 text-sm text-gray-600">
                  <p>
                    <strong>Nombre:</strong> {referrerData.parent.first_name}
                  </p>
                  <p>
                    <strong>Apellido:</strong> {referrerData.parent.last_name}
                  </p>
									<p>
										<strong>Numero de Documento:</strong> {referrerData.parent.nuip}
									</p>
									<p>
										<strong>Telefono:</strong>{" "}
										{referrerData.parent.phone}
									</p>
                </div>
              )}
            </div>
          )}

          <div className="modal-action justify-center mt-4">
            <button
              onClick={confirmRenew}
              className="btn border w-20 bg-blue-500 text-white hover:bg-blue-600"
            >
              Sí
            </button>
            <button
              onClick={handleDeactivateClick}
              className="btn border w-20 bg-red-500 text-white hover:bg-red-600"
            >
              No
            </button>
          </div>
        </div>
      </div>

      {/*
        === The "DeleteClient" Modal ===
        This is triggered only if the user clicked "No" => handleDeactivateClick()
      */}
      {openDeleteModal && (
        <DeleteClient
          data={data}
          onSubmitDeleteClient={onSubmitDeleteClient}
          type="titular"
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
        />
      )}
    </div>
  );
}

export default MembershipRenewModal;
