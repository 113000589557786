import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import {
	get_commercial_managers_list,
	get_commercial_managers_list_page,
} from "../../../redux/actions/commercial-managers/commercial-managers";
import Loading from "../../../components/Loading";

function CommercialManagers({
	get_commercial_managers_list,
	get_commercial_managers_list_page,
	commercial_managers_list,
}) {
	useEffect(() => {
		get_commercial_managers_list();
	}, [get_commercial_managers_list]);

	return (
		<DashboardLayout>
			<Helmet>
				<title>Latir | Commercial Managers</title>
			</Helmet>
			<div className="border-b border-gray-200 bg-white py-5 sm:px-6">
				<div className="flex flex-col flex-wrap lg:flex-row lg:items-center justify-between sm:flex-nowrap">
					<div className="">
						<h3 className="text-2xl font-medium leading-6 text-gray-900">
							Lista de Gestores Comerciales
						</h3>
					</div>
					<div className="flex flex-col lg:flex-row lg:items-center gap-4 py-2 lg:py-0">
						<div className="flex justify-between gap-4">
							<Link to="/commercial-manager-create">
								<button className="bg-[#0096C4] text-white px-4 py-1.5 rounded hover:bg-slate-700">
									Agregar Gestor Comercial
								</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="overflow-hidden bg-white">
				<div className="relative overflow-x-auto">
					<table className="table text-gray-500">
						<thead className="text-xs text-gray-700 uppercase">
							<tr>
								<th scope="col" className="p-2">
									Nombre
								</th>
								<th scope="col" className="p-2">
									Apellido
								</th>
								<th scope="col" className="p-2">
									Cédula
								</th>
								<th scope="col" className="p-2">
									Correo
								</th>
								<th scope="col" className="p-2">
									Teléfono
								</th>
								<th scope="col" className="p-2">
									Acciones
								</th>
							</tr>
						</thead>
						<tbody>
							{commercial_managers_list.length > 0 ? (
								commercial_managers_list.map((manager, index) => (
									<tr key={index}>
										<td className="p-2">{manager.first_name}</td>
										<td className="p-2">{manager.last_name}</td>
										<td className="p-2">{manager.nuip}</td>
										<td className="p-2">{manager.email}</td>
										<td className="p-2">{manager.phone}</td>
										<td className="p-2">
											<Link to={`/commercial-manager-edit/${manager.id}`}>
												<button className="bg-[#0096C4] text-white px-4 py-1.5 rounded hover:bg-slate-700">
													Editar
												</button>
											</Link>
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan="6" className="p-2 text-center">
										<div className="flex justify-center items-center">
											<Loading />
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	commercial_managers_list: state.commercial_managers.commercial_managers_list,
});

export default connect(mapStateToProps, {
	get_commercial_managers_list,
	get_commercial_managers_list_page,
})(CommercialManagers);
