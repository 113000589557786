import { Helmet } from "react-helmet-async";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { useState } from "react";
import Toast from "../../../components/Alert";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function CommissionsForm() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    nivel: "",
    monto_compensation: "",
    rol: "",
  });
  const navigate = useNavigate();
  const { nivel, monto_compensation, rol } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    const fd = new FormData();
    fd.append("nivel", nivel);
    fd.append("monto_compensation", monto_compensation);
    fd.append("rol", rol);

    const fetchData = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/commissions/list/`,
          fd,
          config
        );
        if (res.status === 201) {
          setLoading(false);
          setFormData({
            nivel: "",
            monto_compensation: "",
            rol: "",
          });
          Toast({
            tipo: "success",
            mensaje: "¡Monto de Comisión Registrado Correctamente!",
          });
          navigate("/commissions/users");
        }
      } catch (error) {
        setLoading(false);
        if (
          error.response &&
          error.response.status === 400 &&
          error.response.data.non_field_errors &&
          error.response.data.non_field_errors.includes(
            "Los campos nivel, rol deben formar un conjunto único."
          )
        ) {
          Toast({
            tipo: "error",
            mensaje: `¡Ya cargaste el monto del nivel ${nivel} para el rol ${rol}!`,
          });
        }
      }
    };
    fetchData();
  };

  // Sólo se definen los campos "nivel" y "monto_compensation" para ambos roles
  const roleFieldsMapping = {
    "COORDINADOR JUNIOR": ["nivel", "monto_compensation"],
    "ENLACE": ["nivel", "monto_compensation"],
		"GESTOR COMERCIAL": ["nivel", "monto_compensation"],
  };

  function renderFields() {
    const fieldsToShow = roleFieldsMapping[rol] || [];
    return fieldsToShow.map((field) => (
      <div className="form-control" key={field}>
        <label htmlFor={field} className="label">
          <span className="label-text">{getFieldLabel(field)}</span>
        </label>
        <input
          type="number"
          min={1}
          max={field === "nivel" ? 12 : undefined}
          placeholder={getFieldPlaceholder(field)}
          className="input input-bordered"
          name={field}
          value={formData[field]}
          onChange={onChange}
        />
      </div>
    ));
  }

  function getFieldLabel(field) {
    const labels = {
      nivel: "Nivel",
      monto_compensation: "Monto Compensación",
    };
    return labels[field];
  }

  function getFieldPlaceholder(field) {
    const placeholders = {
      nivel: "Nivel",
      monto_compensation: "Monto Compensación",
    };
    return placeholders[field];
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>Latir | Registro de Comisiones</title>
      </Helmet>
      <div className="w-full h-100 shadow-card rounded-lg overscroll-y-auto overflow-y-auto">
        <h1 className="font-bold text-3xl text-gray-900">
          Registro de Comisiones.
        </h1>
        <form onSubmit={onSubmit}>
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
            Selecciona un Rol
          </div>
          <div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
            <div className="form-control">
              <label htmlFor="rol" className="label">
                <span className="label-text">Rol</span>
              </label>
              <select
                className="input input-bordered"
                name="rol"
                value={rol}
                onChange={onChange}
              >
                <option value="">Selecciona un rol</option>
                <option value="COORDINADOR JUNIOR">COORDINADOR JUNIOR</option>
                <option value="ENLACE">ENLACE</option>
								<option value="GESTOR COMERCIAL">GESTOR COMERCIAL</option>
              </select>
            </div>
          </div>

          {rol && (
            <>
              <div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
                Datos a Cargar
              </div>
              <div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
                {renderFields()}
              </div>

              <div className="mt-4">
                <button type="submit" className="btn btn-primary">
                  Registrar Comisiones
                </button>
              </div>
            </>
          )}
        </form>
      </div>
    </DashboardLayout>
  );
}

export default CommissionsForm;
