import React, { useState } from "react";
import Modal from "react-modal";
import CircleLoader from "react-spinners/CircleLoader";
import axios from "axios";
import Toast from "../Alert";
import moment from "moment";
import { IconUserPlus } from "@tabler/icons-react";

function BeneficiaryModal2({ client, refreshData }) {
	const [loading, setLoading] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const [formData, setFormData] = useState({
		document_type: "",
		first_name: "",
		last_name: "",
		gender: "",
		stratum: "",
		nuip: "",
		birth_date: "",
		address: "",
		municipality: "",
		neighborhood: "",
		phone: "",
		email: "",
		pathologies: "",
		eps: "",
		prepaid_eps: "",
		marital_status: "",
		kinship: "",
	});
	const {
		document_type,
		first_name,
		last_name,
		gender,
		stratum,
		nuip,
		birth_date,
		address,
		municipality,
		neighborhood,
		phone,
		email,
		pathologies,
		eps,
		prepaid_eps,
		marital_status,
		kinship,
	} = formData;
	const handleClose = () => {
		setModalIsOpen(false);
	};
	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		if (nuip) {
			setLoading(true);
			const config = {
				headers: {
					"Content-Type": "multipart/form-data",
					Accept: "application/json",
				},
			};

			const formattedBirthDate = moment(birth_date).format("YYYY-MM-DD");
			const formData = new FormData();
			formData.append("document_type", document_type);
			formData.append("first_name", first_name);
			formData.append("last_name", last_name);
			formData.append("gender", gender);
			formData.append("stratum", stratum);
			formData.append("nuip", nuip);
			formData.append("birth_date", formattedBirthDate);
			formData.append("address", address);
			formData.append("municipality", municipality);
			formData.append("neighborhood", neighborhood);
			formData.append("phone", phone);
			formData.append("email", email);
			formData.append("pathologies", pathologies);
			formData.append("client", client);
			formData.append("eps", eps);
			if (prepaid_eps === "SI") {
				formData.append("prepaid_eps", "True");
			} else {
				formData.append("prepaid_eps", "False");
			}
			formData.append("marital_status", marital_status);
			formData.append("kinship", kinship);
			const fetchData = async () => {
				try {
					const res = await axios.post(
						`${process.env.REACT_APP_API_URL}/beneficiary/create/`,
						formData,
						config
					);

					if (res.status === 201) {
						setLoading(false);
						setFormData({
							document_type: "",
							first_name: "",
							last_name: "",
							gender: "",
							stratum: "",
							nuip: "",
							birth_date: "",
							address: "",
							municipality: "",
							neighborhood: "",
							phone: "",
							email: "",
							pathologies: "",
							eps: "",
							prepaid_eps: "",
							marital_status: "",
							kinship: "",
						});

						Toast({
							tipo: "success",
							mensaje: "¡Beneficiario registrado correctamente!",
						});
						handleClose();
						refreshData();
						setModalIsOpen(false);
					}
				} catch (error) {
					if (error.response && error.response.status === 400) {
						if (
							error.response.data.nuip &&
							error.response.data.nuip.includes(
								"Ya existe Beneficiario con este nuip."
							)
						) {
							Toast({
								tipo: "error",
								mensaje: "Ya se encuentra registrado en nuestra base de datos.",
							});
						} else if (
							error.response.data.error &&
							error.response.data.error.includes(
								"Client already has 3 beneficiaries"
							)
						) {
							Toast({
								tipo: "error",
								mensaje: "El cliente ya tiene 3 beneficiarios registrados.",
							});

							setModalIsOpen(false);
						} else if (
							error.response.data.error &&
							error.response.data.error.includes(
								"Ya existe un beneficiario con el mismo NUIP"
							)
						) {
							Toast({
								tipo: "error",
								mensaje:
									"El Beneficiario ya se encuentra registrado en nuestra base de datos.",
							});
						} else if (
							error.response.data.error &&
							error.response.data.error.includes(
								"Ya existe un cliente con el mismo nuip"
							)
						) {
							Toast({
								tipo: "error",
								mensaje:
									"Estas intentando registrar un titular como beneficiario.",
							});
						} else {
							Toast({
								tipo: "error",
								mensaje: "Error al registrar los datos en el sistema.",
							});
						}
					} else {
						Toast({
							tipo: "error",
							mensaje: "Error al registrar los datos en el sistema.",
						});
					}

					setLoading(false);
				}
			};
			fetchData();
		} else {
			Toast({
				tipo: "error",
				mensaje: "El campo cédula de identidad es obligatorio.",
			});
		}
	};

	return (
		<div>
			<div className="lg:tooltip" data-tip="Agregar beneficiario">
				<button
					type="button"
					className="p-1 bg-blue-400 text-white rounded-full"
					onClick={() => setModalIsOpen(true)}
				>
					<IconUserPlus />
				</button>
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => setModalIsOpen(false)}
				ariaHideApp={false}
			>
				<div className="w-full h-100 shadow-card rounded-lg overflow-y-auto">
					<h1 className="font-bold text-xl text-gray-900">
						Registro de beneficiarios
					</h1>
					<form onSubmit={onSubmit}>
						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Datos personales
						</div>
						<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Tipo de documento</span>
								</label>
								<select
									name="document_type"
									value={document_type}
									onChange={onChange}
									required
									className="select select-bordered w-full"
								>
									<option value="">Selecciona el tipo de documento</option>
									<option value="CC">Cédula de Ciudadanía</option>
									<option value="CE">Cédula de Extranjería</option>
									<option value="PA">Pasaporte</option>
									<option value="TI">Tarjeta de Identidad</option>
									<option value="RC">Registro Civil</option>
									<option value="PE">Permiso Especial de Permanencia</option>
									<option value="PT">Permiso Temporal de Permanencia</option>
								</select>
							</div>

							<div className="form-control">
								<label htmlFor="nuip" className="label">
									<span className="label-text">Cédula de identidad</span>
								</label>
								<input
									type="text"
									name="nuip"
									value={nuip}
									onChange={onChange}
									required
									minLength={6}
									maxLength={11}
									pattern="[0-9]+"
									className="input input-bordered w-full"
									placeholder="Cedula de Identidad"
								/>
							</div>

							<div className="form-control">
								<label htmlFor="first_name" className="label">
									<span className="label-text">Nombres</span>
								</label>
								<input
									type="text"
									name="first_name"
									value={first_name}
									onChange={onChange}
									required
									className={`input input-bordered w-full 
											"border-gray-300 focus:border-orange-500 focus:ring-orange-500"
									}`}
									placeholder="Nombres"
								/>
							</div>

							<div className="form-control">
								<label htmlFor="last_name" className="label">
									<span className="label-text">Apellidos</span>
								</label>
								<input
									type="text"
									name="last_name"
									value={last_name}
									onChange={onChange}
									required
									className={`input input-bordered w-full 
											"border-gray-300 focus:border-orange-500 focus:ring-orange-500"
									}`}
									placeholder="Apellidos"
								/>
							</div>

							<div className="form-control">
								<label htmlFor="gender" className="label">
									<span className="label-text">Género</span>
								</label>
								<select
									name="gender"
									value={gender}
									onChange={onChange}
									required
									className="select select-bordered w-full"
								>
									<option value="">Selecciona el género</option>
									<option value="M">Masculino</option>
									<option value="F">Femenino</option>
									<option value="O">Otro</option>
								</select>
							</div>

							<div className="form-control">
								<label htmlFor="birth_date" className="label">
									<span className="label-text">Fecha de nacimiento</span>
								</label>
								<input
									type="date"
									name="birth_date"
									value={birth_date}
									onChange={onChange}
									required
									max={new Date().toISOString().split("T")[0]}
									className="input input-bordered w-full"
									placeholder="Fecha de Nacimiento"
								/>
							</div>

							<div className="form-control">
								<label htmlFor="marital_status" className="label">
									<span className="label-text">Estado civil</span>
								</label>
								<select
									name="marital_status"
									value={marital_status}
									onChange={onChange}
									required
									className="select select-bordered w-full"
								>
									<option value="">Selecciona el estado civil</option>
									<option value="SOLTERO">Soltero</option>
									<option value="CASADO">Casado</option>
									<option value="UNIONLIBRE">Unión libre</option>
									<option value="DIVORCIADO">Divorciado</option>
									<option value="VIUDO">Viudo</option>
									<option value="SEPARADO">Separado</option>
								</select>
							</div>

							<div className="form-control">
								<label htmlFor="kinship" className="label">
									<span className="label-text">Parentesco</span>
								</label>
								<select
									name="kinship"
									value={kinship}
									onChange={onChange}
									required
									className="select select-bordered w-full"
								>
									<option value="">Selecciona el parentesco</option>
									<option value="Cónyuge">Cónyuge</option>
									<option value="Hijo(a)">Hijo(a)</option>
									<option value="Hermano(a)">Hermano(a)</option>
									<option value="Padre">Padre</option>
									<option value="Madre">Madre</option>
									<option value="Abuelo(a)">Abuelo(a)</option>
									<option value="Nieto(a)">Nieto(a)</option>
									<option value="Tío(a)">Tío(a)</option>
									<option value="Sobrino(a)">Sobrino(a)</option>
									<option value="Primo(a)">Primo(a)</option>
									<option value="Suegro(a)">Suegro(a)</option>
									<option value="Yerno(a)">Yerno(a)</option>
									<option value="Cuñado(a)">Cuñado(a)</option>
									<option value="Amigo(a)">Amigo(a)</option>
									<option value="Vecino(a)">Vecino(a)</option>
								</select>
							</div>
						</div>

						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Contacto
						</div>
						<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
							<div className="form-control">
								<label htmlFor="phone" className="label">
									<span className="label-text">Número de teléfono</span>
								</label>
								<input
									type="text"
									name="phone"
									value={phone}
									onChange={onChange}
									required
									pattern="^3[0-9]{9}$"
									className="input input-bordered w-full"
									placeholder="Numero de Telefono"
								/>
							</div>

							<div className="form-control">
								<label htmlFor="email" className="label">
									<span className="label-text">Correo electrónico</span>
								</label>
								<input
									type="email"
									name="email"
									value={email}
									onChange={onChange}
									className="input input-bordered w-full"
									placeholder="Correo Electronico"
								/>
							</div>
						</div>
						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Datos de dirección
						</div>
						<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
							<div className="form-control">
								<label htmlFor="stratum" className="label">
									<span className="label-text">Estrato</span>
								</label>
								<select
									name="stratum"
									value={stratum}
									onChange={onChange}
									className="select select-bordered w-full"
								>
									<option value="">Selecciona el Estrato</option>
									<option value="1">Estrato 1</option>
									<option value="2">Estrato 2</option>
									<option value="3">Estrato 3</option>
									<option value="4">Estrato 4</option>
									<option value="5">Estrato 5</option>
									<option value="6">Estrato 6</option>
								</select>
							</div>

							<div className="form-control">
								<label htmlFor="municipality" className="label">
									<span className="label-text">Municipio</span>
								</label>
								<select
									name="municipality"
									value={municipality}
									onChange={onChange}
									required
									className="select select-bordered w-full"
								>
									<option value="">Selecciona el municipio</option>
									<option value="AR">Arboledas</option>
									<option value="CA">Cucutilla</option>
									<option value="GR">Gramalote</option>
									<option value="LO">Lourdes</option>
									<option value="SP">Salazar de Las Palmas</option>
									<option value="SA">Santiago</option>
									<option value="VC">Villa Caro</option>
									<option value="CU">Cúcuta</option>
									<option value="EZ">El Zulia</option>
									<option value="LP">Los Patios</option>
									<option value="PS">Puerto Santander</option>
									<option value="SC">San Cayetano</option>
									<option value="VR">Villa del Rosario</option>
									<option value="BU">Bucarasica</option>
									<option value="ET">El Tarra</option>
									<option value="SAR">Sardinata</option>
									<option value="TI">Tibú</option>
									<option value="AB">Ábrego</option>
									<option value="CAC">Cáchira</option>
									<option value="CON">Convención</option>
									<option value="EC">El Carmen</option>
									<option value="HA">Hacarí</option>
									<option value="LE">La Esperanza</option>
									<option value="LPB">La Playa de Belén</option>
									<option value="OC">Ocaña</option>
									<option value="SCA">San Calixto</option>
									<option value="TE">Teorama</option>
									<option value="CAA">Cácota</option>
									<option value="CH">Chitagá</option>
									<option value="MU">Mutiscua</option>
									<option value="PA">Pamplona</option>
									<option value="PAM">Pamplonita</option>
									<option value="SDS">Santo Domingo de Silos</option>
									<option value="BO">Bochalema</option>
									<option value="CHI">Chinácota</option>
									<option value="DU">Durania</option>
									<option value="HER">Herrán</option>
									<option value="LAB">Labateca</option>
									<option value="RAG">Ragonvalia</option>
									<option value="TO">Toledo</option>
								</select>
							</div>

							<div className="form-control col-span-1 md:col-span-2 lg:col-span-3">
								<label htmlFor="address" className="label">
									<span className="label-text">Dirección</span>
								</label>
								<textarea
									type="text"
									name="address"
									value={address}
									onChange={onChange}
									required
									maxLength={250}
									className="textarea textarea-bordered w-full resize-none"
									placeholder="Direccion"
								></textarea>
							</div>
						</div>

						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Salud
						</div>

						<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
							<div className="form-control">
								<label htmlFor="eps" className="label">
									<span className="label-text">EPS</span>
								</label>
								<select
									name="eps"
									value={eps}
									onChange={(e) => onChange(e)}
									className="select select-bordered w-full"
								>
									<option value="">Selecciona la EPS</option>
									<option value="ALIANSALUD">ALIANSALUD</option>
									<option value="CAPITAL SALUD">CAPITAL SALUD</option>
									<option value="CAPRESOCA">CAPRESOCA EPS</option>
									<option value="COMPENSAR">COMPENSAR E.P.S.</option>
									<option value="COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL DE CARTAGENA">
										COOPERATIVA DE SALUD Y DESARROLLO INTEGRAL ZONA SUR ORIENTAL
										DE CARTAGENA
									</option>
									<option value="E.P.S.  FAMISANAR LTDA.">
										E.P.S. FAMISANAR LTDA.{" "}
									</option>
									<option value="E.P.S.  SANITAS S.A.">
										E.P.S. SANITAS S.A.
									</option>
									<option value="EPS SERVICIO OCCIDENTAL DE SALUD S.A.">
										EPS SERVICIO OCCIDENTAL DE SALUD S.A.
									</option>
									<option value="EPS Y MEDICINA PREPAGADA SURAMERICANA S.A">
										EPS Y MEDICINA PREPAGADA SURAMERICANA S.A
									</option>
									<option value="FUNDACIÓN SALUD MIA EPS">
										FUNDACIÓN SALUD MIA EPS
									</option>
									<option value="MALLAMAS">MALLAMAS</option>
									<option value="NUEVA EPS S.A.">NUEVA EPS S.A.</option>
									<option value="SALUD TOTAL S.A.  E.P.S.">
										SALUD TOTAL S.A. E.P.S.
									</option>
									<option value="SALUDVIDA S.A. E.P.S">
										SALUDVIDA S.A. E.P.S
									</option>
									<option value="SAVIA SALUD EPS">SAVIA SALUD EPS</option>
								</select>
							</div>
							<div className="form-control">
								<label htmlFor="prepaid_eps" className="label">
									<span className="label-text">EPS Prepagada</span>
								</label>
								<div className="flex gap-3">
									<div className="form-control">
										<label className="label cursor-pointer">
											<span className="label-text mr-2">Sí</span>
											<input
												type="radio"
												name="prepaid_eps"
												className="radio checked:bg-[#00496B]"
												value="SI"
												onChange={(e) => onChange(e)}
											/>
										</label>
									</div>
									<div className="form-control">
										<label className="label cursor-pointer">
											<span className="label-text mr-2">No</span>
											<input
												type="radio"
												name="prepaid_eps"
												className="radio checked:bg-[#00496B]"
												value="NO"
												onChange={(e) => onChange(e)}
											/>
										</label>
									</div>
								</div>
							</div>

							<div className="form-control col-span-1 md:col-span-2 lg:col-span-3">
								<label htmlFor="pathologies" className="label">
									<span className="label-text">Patologías</span>
								</label>
								<textarea
									type="text"
									name="pathologies"
									value={pathologies}
									onChange={onChange}
									maxLength={250}
									className="textarea textarea-bordered resize-none"
									placeholder="Patologias"
								/>
							</div>
						</div>

						<div className="flex justify-center mt-4 space-x-3">
							<button
								type="submit"
								className="btn border border-[#0096C4] bg-white text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B]"
								onClick={() => setModalIsOpen(false)}
							>
								Cerrar
							</button>
							{loading ? (
								<div className="btn border bg-[#0096C4] text-white">
									<CircleLoader loading={loading} size={25} color="#ffffff" />
								</div>
							) : (
								<button
									type="button"
									className="btn border bg-[#0096C4] hover:bg-[#00496B] text-white"
									onClick={onSubmit}
								>
									Guardar
								</button>
							)}
						</div>
					</form>
				</div>
			</Modal>
		</div>
	);
}

export default BeneficiaryModal2;
