import axios from "axios";

import {
	GET_COMMERCIAL_MANAGERS_LIST_SUCCESS,
	GET_COMMERCIAL_MANAGERS_LIST_FAIL,
	GET_COMMERCIAL_MANAGERS_FAIL,
	GET_COMMERCIAL_MANAGERS_SUCCESS,
} from "./types";

export const get_commercial_managers_list = (min = false) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
    },
    params: {
      min: min ? "true" : "false",
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/commercial-manager/list/`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_COMMERCIAL_MANAGERS_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_COMMERCIAL_MANAGERS_LIST_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_COMMERCIAL_MANAGERS_LIST_FAIL,
    });
  }
};


export const get_commercial_managers_list_page = (p) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
		},
	};

	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/commercial-manager/list/?p=${p}`,
			config
		);

		if (res.status === 200) {
			dispatch({
				type: GET_COMMERCIAL_MANAGERS_LIST_SUCCESS,
				payload: res.data,
			});
		}
	} catch {
		dispatch({
			type: GET_COMMERCIAL_MANAGERS_LIST_FAIL,
		});
	}
};

export const get_commercial_manager = (id) => async (dispatch) => {
	const config = {
		headers: {
			Accept: "application/json",
		},
	};

	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/commercial-manager/${id}/`,
			config
		);

		if (res.status === 200) {
			dispatch({
				type
				: GET_COMMERCIAL_MANAGERS_SUCCESS,
				payload: res.data,
			});
		}
	}
	catch {
		dispatch({
			type: GET_COMMERCIAL_MANAGERS_FAIL,
		});
	}
}