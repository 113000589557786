import { CircleLoader } from "react-spinners";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import Toast from "../../../components/Alert";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get_commercial_manager } from "../../../redux/actions/commercial-managers/commercial-managers";
import { connect } from "react-redux";

function ComercialManagerEdit({ get_commercial_manager, commercial_manager }) {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { id } = useParams();

	// Al cargar el componente se obtiene el commercial_manager según el id
	useEffect(() => {
		get_commercial_manager(id);
	}, [get_commercial_manager, id]);

	// Estado para los valores del formulario
	const [formData, setFormData] = useState({
		nuip: "",
		first_name: "",
		last_name: "",
		phone: "",
		email: "",
	});
	const { nuip, first_name, last_name, phone, email } = formData;
	// Cuando se actualiza el commercial_manager se actualiza el formData
	useEffect(() => {
		if (commercial_manager) {
			setFormData({
				nuip: commercial_manager.nuip || "",
				first_name: commercial_manager.first_name || "",
				last_name: commercial_manager.last_name || "",
				phone: commercial_manager.phone || "",
				email: commercial_manager.email || "",
			});
		}
	}, [commercial_manager]);

	// Estados para controlar si se está editando cada campo
	const [updateNuip, setUpdateNuip] = useState(false);
	const [updateFirstName, setUpdateFirstName] = useState(false);
	const [updateLastName, setUpdateLastName] = useState(false);
	const [updatePhone, setUpdatePhone] = useState(false);
	const [updateEmail, setUpdateEmail] = useState(false);

	// Manejo de cambios en los inputs
	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	// Función para enviar los cambios
	const onSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const res = await axios.put(
				`${process.env.REACT_APP_API_URL}/commercial-manager/edit/${id}/`,
				formData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `JWT ${localStorage.getItem("access")}`,
					},
				}
			);
			setLoading(false);
			Toast({
				tipo: "success",
				mensaje: res.data.success || "Actualizado correctamente",
			});
			// Vuelve a obtener el commercial_manager actualizado
			get_commercial_manager(id);
			// Reinicia los estados de edición
			setUpdateNuip(false);
			setUpdateFirstName(false);
			setUpdateLastName(false);
			setUpdatePhone(false);
			setUpdateEmail(false);
		} catch (error) {
			setLoading(false);
			Toast({ tipo: "error", mensaje: "Error al actualizar" });
		}
	};

	return (
		<DashboardLayout>
			<Helmet>
				<title>Editar Gestor Comercial</title>
			</Helmet>
			<div className="w-full overflow-y-auto rounded-lg h-100 shadow-card overscroll-y-auto">
				<h1 className="text-3xl font-bold text-gray-900">
					Editar Gestor Comercial.
				</h1>

				<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
					Datos personales
				</div>
				<div className="grid grid-cols-1 gap-3 p-3 border border-gray-300 rounded-lg md:grid-cols-2 lg:grid-cols-3">
					<div className="form-control">
						<label htmlFor="document_type" className="label">
							<span className="label-text">Número de documento</span>
						</label>
						{updateNuip ? (
							<>
								<form onSubmit={(e) => onSubmit(e)} className="">
									<span className="flex-grow">
										<input
											value={nuip}
											onChange={(e) => onChange(e)}
											name="nuip"
											type="text"
											className="w-full input input-bordered"
											pattern="[0-9]+"
											minLength={6}
											maxLength={11}
											required
										/>
									</span>
									<div className="flex gap-4 mt-3">
										<button
											type="submit"
											className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
										>
											Guardar
										</button>
										<button
											type="button"
											onClick={() => setUpdateNuip(false)}
											className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
										>
											Cancelar
										</button>
									</div>
								</form>
							</>
						) : (
							<>
								<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
									{commercial_manager.nuip}
								</span>
								<div className="mt-3">
									<button
										type="button"
										onClick={() => setUpdateNuip(true)}
										className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
									>
										Modificar
									</button>
								</div>
							</>
						)}
					</div>

					<div className="form-control">
						<label htmlFor="first_name" className="label">
							<span className="label-text">Nombres</span>
						</label>
						{updateFirstName ? (
							<>
								<form onSubmit={(e) => onSubmit(e)} className="">
									<span className="flex-grow">
										<input
											value={first_name}
											onChange={(e) => onChange(e)}
											name="first_name"
											type="text"
											className="w-full input input-bordered"
											required
										/>
									</span>
									<div className="flex gap-4 mt-3">
										<button
											type="submit"
											className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
										>
											Guardar
										</button>
										<button
											type="button"
											onClick={() => setUpdateFirstName(false)}
											className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
										>
											Cancelar
										</button>
									</div>
								</form>
							</>
						) : (
							<>
								<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
									{commercial_manager.first_name}
								</span>
								<div className="mt-3">
									<button
										type="button"
										onClick={() => setUpdateFirstName(true)}
										className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
									>
										Modificar
									</button>
								</div>
							</>
						)}
					</div>

					<div className="form-control">
						<label htmlFor="last_name" className="label">
							<span className="label-text">Apellidos</span>
						</label>
						{updateLastName ? (
							<>
								<form onSubmit={(e) => onSubmit(e)} className="">
									<span className="flex-grow">
										<input
											value={last_name}
											onChange={(e) => onChange(e)}
											name="last_name"
											type="text"
											className="w-full input input-bordered"
											required
										/>
									</span>
									<div className="flex gap-4 mt-3">
										<button
											type="submit"
											className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
										>
											Guardar
										</button>
										<button
											type="button"
											onClick={() => setUpdateLastName(false)}
											className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
										>
											Cancelar
										</button>
									</div>
								</form>
							</>
						) : (
							<>
								<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
									{commercial_manager.last_name}
								</span>
								<div className="mt-3">
									<button
										type="button"
										onClick={() => setUpdateLastName(true)}
										className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
									>
										Modificar
									</button>
								</div>
							</>
						)}
					</div>
				</div>
				<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
					Contacto
				</div>
				<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
					<div className="form-control">
						<label htmlFor="phone" className="label">
							<span className="label-text">Teléfono</span>
						</label>
						{updatePhone ? (
							<>
								<form onSubmit={(e) => onSubmit(e)} className="">
									<span className="flex-grow">
										<input
											value={phone}
											onChange={(e) => onChange(e)}
											name="phone"
											type="text"
											className="input input-bordered w-full"
											pattern="^3[0-9]{9}$"
											required
										/>
									</span>
									<div className="flex gap-4 mt-3">
										<button
											type="submit"
											className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
										>
											Guardar
										</button>
										<button
											type="button"
											onClick={() => setUpdatePhone(false)}
											className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
										>
											Cancelar
										</button>
									</div>
								</form>
							</>
						) : (
							<>
								<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
									{commercial_manager.phone}
								</span>
								<div className="mt-3">
									<button
										type="button"
										onClick={() => setUpdatePhone(true)}
										className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
									>
										Modificar
									</button>
								</div>
							</>
						)}
					</div>

					<div className="form-control">
						<label htmlFor="email" className="label">
							<span className="label-text">Correo electrónico</span>
						</label>
						{updateEmail ? (
							<>
								<form onSubmit={(e) => onSubmit(e)} className="">
									<span className="flex-grow">
										<input
											value={email}
											onChange={(e) => onChange(e)}
											name="email"
											type="email"
											className="w-full input input-bordered"
											required
										/>
									</span>
									<div className="flex gap-4 mt-3">
										<button
											type="submit"
											className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
										>
											Guardar
										</button>
										<button
											type="button"
											onClick={() => setUpdateEmail(false)}
											className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
										>
											Cancelar
										</button>
									</div>
								</form>
							</>
						) : (
							<>
								<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
									{commercial_manager.email}
								</span>
								<div className="mt-3">
									<button
										type="button"
										onClick={() => setUpdateEmail(true)}
										className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
									>
										Modificar
									</button>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	commercial_manager: state.commercial_managers.commercial_managers,
});

export default connect(mapStateToProps, { get_commercial_manager })(
	ComercialManagerEdit
);
