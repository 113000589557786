import React, { useEffect, useState } from "react";
import axios from "axios";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { get_commission } from "../../../redux/actions/commissions/commissions";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Toast from "../../../components/Alert";

function CommissionsEdit({ get_commission, commission }) {
  const params = useParams();
  const id = params.id;

  const [formData, setFormData] = useState({
    nivel: "",
    monto_compensation: "",
    updateNivel: false,
    updateMontoCompensation: false,
  });

  useEffect(() => {
    get_commission(id);
  }, [id]);

  useEffect(() => {
    if (commission) {
      setFormData({
        nivel: commission.nivel || "",
        monto_compensation: commission.monto_compensation || "",
        updateNivel: false,
        updateMontoCompensation: false,
      });
    }
  }, [commission]);

  const resetStates = () => {
    setFormData((prevState) => ({
      ...prevState,
      updateNivel: false,
      updateMontoCompensation: false,
    }));
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const [loading, setLoading] = useState(false);

  const onSubmitField = (e, field) => {
    e.preventDefault();
    setLoading(true);
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const fd = new FormData();
    fd.append("id", id);
    fd.append(field, e.target[field].value);

    const fetchData = async () => {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_API_URL}/commissions/edit/${id}/`,
          fd,
          config
        );
        if (res.status === 200) {
          setLoading(false);
          get_commission(id);
          resetStates();
          Toast({ tipo: "success", mensaje: res.data.success });
        } else {
          setLoading(false);
          resetStates();
          Toast({ tipo: "error", mensaje: res.data.error });
        }
      } catch (err) {
        setLoading(false);
        resetStates();
        Toast({ tipo: "error", mensaje: "Error al editar la comisión" });
      }
    };
    fetchData();
  };

  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  function renderFieldEdit(field) {
    const updateField = `update${capitalizeFirstLetter(field)}`;

    return formData[updateField] ? (
      <form onSubmit={(e) => onSubmitField(e, field)} className="">
        <input
          type="number"
          name={field}
          value={formData[field]}
          onChange={onChange}
          required
          className="input input-bordered w-full"
        />
        <div className="mt-3 flex gap-4">
          <button
            type="submit"
            className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
          >
            Guardar
          </button>
          <div
            onClick={() =>
              setFormData({ ...formData, [updateField]: false })
            }
            className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
          >
            Cancelar
          </div>
        </div>
      </form>
    ) : (
      <>
        <span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
          {formData[field] ? formData[field] : "Sin Especificar"}
        </span>
        <div className="mt-3">
          <div
            onClick={() =>
              setFormData({ ...formData, [updateField]: true })
            }
            className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
          >
            Modificar
          </div>
        </div>
      </>
    );
  }

  // Solo se muestran los campos "nivel" y "monto_compensation" para los roles "COORDINADOR JUNIOR" y "ENLACE"
  const roleFieldsMapping = {
    "COORDINADOR JUNIOR": ["nivel", "monto_compensation"],
    ENLACE: ["nivel", "monto_compensation"],
		"GESTOR COMERCIAL": ["nivel", "monto_compensation"],
  };

  function renderFields() {
    const fieldsToShow = roleFieldsMapping[commission.rol] || [];
    return fieldsToShow.map((field, index) => (
      <div className="form-control" key={index}>
        <label htmlFor={field} className="label">
          <span className="label-text">{getFieldLabel(field)}</span>
        </label>
        {renderFieldEdit(field)}
      </div>
    ));
  }

  function getFieldLabel(field) {
    const labels = {
      nivel: "Nivel",
      monto_compensation: "Monto Compensación",
    };
    return labels[field];
  }

  return (
    <DashboardLayout>
      <div className="w-full h-100 shadow-card rounded-lg overscroll-y-auto overflow-y-auto">
        <h1 className="font-bold text-3xl text-gray-900">
          Editar monto para calcular las comisiones
        </h1>

        {commission && commission.rol ? (
          <>
            <div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
              Datos a Cargar
            </div>
            <div className="border border-gray-300 rounded-lg p-3">
              <div>
                <h2>
                  <span className="font-bold">ROL: </span>
                  <span className="py-3">{commission.rol}</span>
                </h2>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                {renderFields()}
              </div>
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  commission: state.commissions.commission,
});

export default connect(mapStateToProps, { get_commission })(CommissionsEdit);
