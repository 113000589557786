import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import Toast from "../../../components/Alert";
import { get_beneficiary } from "../../../redux/actions/client/client";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import Loading from "../../../components/Loading";

function BeneficiarieEdit({ get_beneficiary, beneficiary }) {
	const user = useSelector((state) => state.auth.user);
	const params = useParams();
	const id = params.id;

	useEffect(() => {
		window.scrollTo(0, 0);
		get_beneficiary(id);
	}, [get_beneficiary, id]);

	const [updateDocumentType, setUpdateDocumentType] = useState(false);
	const [updateFirstName, setUpdateFirstName] = useState(false);
	const [updateLastName, setUpdateLastName] = useState(false);
	const [updateGender, setUpdateGender] = useState(false);
	const [updateStratum, setUpdateStratum] = useState(false);
	const [updateNuip, setUpdateNuip] = useState(false);
	const [updateBirthDate, setUpdateBirthDate] = useState(false);
	const [updateAddress, setUpdateAddress] = useState(false);
	const [updateMunicipality, setUpdateMunicipality] = useState(false);
	const [updatePhone, setUpdatePhone] = useState(false);
	const [updateEmail, setUpdateEmail] = useState(false);
	const [updatePathologies, setUpdatePathologies] = useState(false);
	const [updateNumberQueries, setUpdateNumberQueries] = useState(false);
	const [updateMaritalStatus, setUpdateMaritalStatus] = useState(false);
	const [updateKinship, setUpdateKinship] = useState(false);
	const [formData, setFormData] = useState({
		document_type: "",
		first_name: "",
		last_name: "",
		gender: "",
		stratum: "",
		nuip: "",
		birth_date: "",
		address: "",
		municipality: "",
		neighborhood: "",
		phone: "",
		email: "",
		pathologies: "",
		number_queries: "",
		marital_status: "",
		kinship: "",
	});
	const {
		document_type,
		first_name,
		last_name,
		gender,
		stratum,
		nuip,
		birth_date,
		address,
		municipality,
		neighborhood,
		phone,
		email,
		pathologies,
		number_queries,
		marital_status,
		kinship,
	} = formData;

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};
	const [loading, setLoading] = useState(false);

	const resetStates = () => {
		setUpdateDocumentType(false);
		setUpdateFirstName(false);
		setUpdateLastName(false);
		setUpdateGender(false);
		setUpdateStratum(false);
		setUpdateNuip(false);
		setUpdateBirthDate(false);
		setUpdateAddress(false);
		setUpdateMunicipality(false);
		setUpdateNumberQueries(false);
		setUpdatePhone(false);
		setUpdateEmail(false);
		setUpdatePathologies(false);
		setUpdateMaritalStatus(false);
		setUpdateKinship(false);
	};
	const onSubmit = (e) => {
		e.preventDefault();
		const config = {
			headers: {
				Accept: "application/json",
				"Content-Type": "multipart/form-data",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		const formData = new FormData();
		formData.append("document_type", document_type);
		formData.append("first_name", first_name);
		formData.append("last_name", last_name);
		formData.append("gender", gender);
		formData.append("stratum", stratum);
		formData.append("nuip", nuip);
		formData.append("birth_date", birth_date);
		formData.append("address", address);
		formData.append("municipality", municipality);
		formData.append("neighborhood", neighborhood);
		formData.append("phone", phone);
		formData.append("email", email);
		formData.append("pathologies", pathologies);
		formData.append("number_queries", number_queries);
		formData.append("id", id);
		formData.append("marital_status", marital_status);
		formData.append("kinship", kinship);
		const fetchData = async () => {
			setLoading(true);
			try {
				const res = await axios.put(
					`${process.env.REACT_APP_API_URL}/beneficiary/edit/${id}/`,
					formData,
					config
				);
				if (res.status === 200) {
					setFormData({
						document_type: "",
						first_name: "",
						last_name: "",
						gender: "",
						stratum: "",
						nuip: "",
						birth_date: "",
						address: "",
						municipality: "",
						neighborhood: "",
						phone: "",
						email: "",
						pathologies: "",
						marital_status: "",
						kinship: "",
					});
					setLoading(false);
					resetStates();
					get_beneficiary(id);
					Toast({ tipo: "success", mensaje: res.data.success });
				} else {
					setLoading(false);
					resetStates();
					Toast({ tipo: "error", mensaje: res.data.error });
				}
			} catch (err) {
				setLoading(false);
				resetStates();
				Toast({ tipo: "error", mensaje: "Error al editar el Beneficiario" });
			}
		};
		fetchData();
	};
	return (
		<DashboardLayout>
			{beneficiary ? (
				<>
					<Helmet>
						<title>Latir | Editar beneficiario</title>
					</Helmet>
					<div className="w-full h-100 shadow-card rounded-lg overscroll-y-auto overflow-y-auto">
						<h1 className="font-bold text-3xl  text-gray-900">
							Editar beneficiario.
						</h1>

						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Datos personales
						</div>
						<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Tipo de documento</span>
								</label>
								{updateDocumentType ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="">
												<select
													name="document_type"
													value={document_type}
													onChange={(e) => onChange(e)}
													required
													className="select select-bordered w-full"
												>
													<option value="">
														Selecciona el tipo de documento
													</option>
													<option value="CC">Cédula de ciudadanía</option>
													<option value="CE">Cédula de extranjería</option>
													<option value="PA">Pasaporte</option>
													<option value="TI">Tarjeta de identidad</option>
													<option value="RC">Registro civil</option>
													<option value="PE">
														Permiso especial de permanencia
													</option>
													<option value="PT">
														Permiso temporal de permanencia
													</option>
												</select>
											</span>
											<div className="mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateDocumentType(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<div className="px-4 py-3 bg-gray-100 rounded-lg w-full">
											{beneficiary && beneficiary.document_type
												? (() => {
														switch (beneficiary.document_type) {
															case "CC":
																return "Cédula de Ciudadanía";
															case "CE":
																return "Cédula de Extranjería";
															case "PA":
																return "Pasaporte";
															case "TI":
																return "Tarjeta de Identidad";
															case "RC":
																return "Registro Civil";
															case "PE":
																return "Permiso Especial de Permanencia";
															case "PT":
																return "Permiso Temporal de Permanencia";
															default:
																return "Sin Especificar";
														}
												  })()
												: "Sin Especificar"}
										</div>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateDocumentType(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Número de documento</span>
								</label>
								{updateNuip ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													value={nuip}
													onChange={(e) => onChange(e)}
													name="nuip"
													type="text"
													pattern="[0-9]+"
													min={0}
													maxLength={11}
													className="input input-bordered w-full"
													required
												/>
											</span>
											<div className=" mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateNuip(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
											{beneficiary.nuip}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateNuip(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Nombres</span>
								</label>
								{updateFirstName ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													value={first_name}
													onChange={(e) => onChange(e)}
													name="first_name"
													type="text"
													className="input input-bordered w-full"
													required
												/>
											</span>
											<div className="mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateFirstName(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
											{beneficiary.first_name}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateFirstName(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Apellidos</span>
								</label>
								{updateLastName ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													value={last_name}
													onChange={(e) => onChange(e)}
													name="last_name"
													type="text"
													className="input input-bordered w-full"
													required
												/>
											</span>
											<div className="mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateLastName(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
											{beneficiary.last_name}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateLastName(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Género</span>
								</label>
								{updateGender ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<select
													name="gender"
													value={gender}
													onChange={(e) => onChange(e)}
													required
													className="select select-bordered w-full"
												>
													<option value="">Selecciona el género</option>
													<option value="M">Masculino</option>
													<option value="F">Femenino</option>
													<option value="O">Otro</option>
												</select>
											</span>
											<div className="mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateGender(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
											{beneficiary && beneficiary.gender
												? beneficiary.gender === "M"
													? "Masculino"
													: "Femenino"
												: "Sin Especificar"}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateGender(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Fecha de nacimiento</span>
								</label>
								{updateBirthDate ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													value={birth_date}
													onChange={(e) => onChange(e)}
													name="birth_date"
													type="date"
													className="input input-bordered w-full"
													required
													max={new Date().toISOString().split("T")[0]}
												/>
											</span>
											<div className="mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateBirthDate(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
											{beneficiary.birth_date}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateBirthDate(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="marital_status" className="label">
									<span className="label-text">Estado civil</span>
								</label>
								{updateMaritalStatus ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<select
													name="marital_status"
													value={marital_status}
													onChange={(e) => onChange(e)}
													required
													className="select select-bordered w-full"
												>
													<option value="">Selecciona el estado civil</option>
													<option value="SOLTERO">Soltero</option>
													<option value="CASADO">Casado</option>
													<option value="UNIONLIBRE">Unión libre</option>
													<option value="DIVORCIADO">Divorciado</option>
													<option value="VIUDO">Viudo</option>
													<option value="SEPARADO">Separado</option>
												</select>
											</span>
											<div className="mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateMaritalStatus(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
											{beneficiary && beneficiary.marital_status
												? beneficiary.marital_status
												: "Sin Especificar"}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateMaritalStatus(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496C4] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="kinship" className="label">
									<span className="label-text">Parentesco</span>
								</label>
								{updateKinship ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<select
													name="kinship"
													value={kinship}
													onChange={(e) => onChange(e)}
													required
													className="select select-bordered w-full"
												>
													<option value="">Selecciona el parentesco</option>
													<option value="Cónyuge">Cónyuge</option>
													<option value="Hijo(a)">Hijo(a)</option>
													<option value="Hermano(a)">Hermano(a)</option>
													<option value="Padre">Padre</option>
													<option value="Madre">Madre</option>
													<option value="Abuelo(a)">Abuelo(a)</option>
													<option value="Nieto(a)">Nieto(a)</option>
													<option value="Tío(a)">Tío(a)</option>
													<option value="Sobrino(a)">Sobrino(a)</option>
													<option value="Primo(a)">Primo(a)</option>
													<option value="Suegro(a)">Suegro(a)</option>
													<option value="Yerno(a)">Yerno(a)</option>
													<option value="Cuñado(a)">Cuñado(a)</option>
													<option value="Amigo(a)">Amigo(a)</option>
													<option value="Vecino(a)">Vecino(a)</option>
													<option value="Otro">Otro</option>
												</select>
											</span>
											<div className="mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496C4] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateKinship(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496C4] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
											{beneficiary && beneficiary.kinship
												? beneficiary.kinship
												: "Sin Especificar"}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateKinship(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496C4] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>
						</div>

						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Contacto
						</div>
						<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Teléfono</span>
								</label>
								{updatePhone ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													value={phone}
													onChange={(e) => onChange(e)}
													name="phone"
													type="text"
													pattern="^3[0-9]{9}$"
													className="input input-bordered w-full"
													required
												/>
											</span>
											<div className="mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdatePhone(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
											{beneficiary.phone}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdatePhone(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Correo electrónico</span>
								</label>
								{updateEmail ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<input
													value={email}
													onChange={(e) => onChange(e)}
													name="email"
													type="email"
													className="input input-bordered w-full"
													required
												/>
											</span>
											<div className="mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateEmail(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
											{beneficiary.email}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateEmail(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>
						</div>

						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Datos de dirección
						</div>
						<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Estrato</span>
								</label>
								{updateStratum ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<select
													name="stratum"
													value={stratum}
													onChange={(e) => onChange(e)}
													required
													className="select select-bordered w-full"
												>
													<option value="">Selecciona el Estrato</option>
													<option value="1">Estrato 1</option>
													<option value="2">Estrato 2</option>
													<option value="3">Estrato 3</option>
													<option value="4">Estrato 4</option>
													<option value="5">Estrato 5</option>
													<option value="6">Estrato 6</option>
												</select>
											</span>
											<div className="mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateStratum(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
											{beneficiary.stratum}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateStratum(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Municipio</span>
								</label>
								{updateMunicipality ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<select
													name="municipality"
													value={municipality}
													onChange={(e) => onChange(e)}
													required
													className="select select-bordered w-full"
												>
													<option value="">Selecciona el Municipio</option>
													<option value="AR">Arboledas</option>
													<option value="CA">Cucutilla</option>
													<option value="GR">Gramalote</option>
													<option value="LO">Lourdes</option>
													<option value="SP">Salazar de Las Palmas</option>
													<option value="SA">Santiago</option>
													<option value="VC">Villa Caro</option>
													<option value="CU">Cúcuta</option>
													<option value="EZ">El Zulia</option>
													<option value="LP">Los Patios</option>
													<option value="PS">Puerto Santander</option>
													<option value="SC">San Cayetano</option>
													<option value="VR">Villa del Rosario</option>
													<option value="BU">Bucarasica</option>
													<option value="ET">El Tarra</option>
													<option value="SAR">Sardinata</option>
													<option value="TI">Tibú</option>
													<option value="AB">Ábrego</option>
													<option value="CAC">Cáchira</option>
													<option value="CON">Convención</option>
													<option value="EC">El Carmen</option>
													<option value="HA">Hacarí</option>
													<option value="LE">La Esperanza</option>
													<option value="LPB">La Playa de Belén</option>
													<option value="OC">Ocaña</option>
													<option value="SCA">San Calixto</option>
													<option value="TE">Teorama</option>
													<option value="CAA">Cácota</option>
													<option value="CH">Chitagá</option>
													<option value="MU">Mutiscua</option>
													<option value="PA">Pamplona</option>
													<option value="PAM">Pamplonita</option>
													<option value="SDS">Santo Domingo de Silos</option>
													<option value="BO">Bochalema</option>
													<option value="CHI">Chinácota</option>
													<option value="DU">Durania</option>
													<option value="HER">Herrán</option>
													<option value="LAB">Labateca</option>
													<option value="RAG">Ragonvalia</option>
													<option value="TO">Toledo</option>
												</select>
											</span>
											<div className="mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateMunicipality(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
											{beneficiary && beneficiary.municipality
												? (() => {
														switch (beneficiary.municipality) {
															case "AR":
																return "Arboledas";
															case "CA":
																return "Cucutilla";
															case "GR":
																return "Gramalote";
															case "LO":
																return "Lourdes";
															case "SP":
																return "Salazar de Las Palmas";
															case "SA":
																return "Santiago";
															case "VC":
																return "Villa Caro";
															case "CU":
																return "Cúcuta";
															case "EZ":
																return "El Zulia";
															case "LP":
																return "Los Patios";
															case "PS":
																return "Puerto Santander";
															case "SC":
																return "San Cayetano";
															case "VR":
																return "Villa del Rosario";
															case "BU":
																return "Bucarasica";
															case "ET":
																return "El Tarra";
															case "SAR":
																return "Sardinata";
															case "TI":
																return "Tibú";
															case "AB":
																return "Ábrego";
															case "CAC":
																return "Cáchira";
															case "CON":
																return "Convención";
															case "EC":
																return "El Carmen";
															case "HA":
																return "Hacarí";
															case "LE":
																return "La Esperanza";
															case "LPB":
																return "La Playa de Belén";
															case "OC":
																return "Ocaña";
															case "SCA":
																return "San Calixto";
															case "TE":
																return "Teorama";
															case "CAA":
																return "Cácota";
															case "CH":
																return "Chitagá";
															case "MU":
																return "Mutiscua";
															case "PA":
																return "Pamplona";
															case "PAM":
																return "Pamplonita";
															case "SDS":
																return "Santo Domingo de Silos";
															case "BO":
																return "Bochalema";
															case "CHI":
																return "Chinácota";
															case "DU":
																return "Durania";
															case "HER":
																return "Herrán";
															case "LAB":
																return "Labateca";
															case "RAG":
																return "Ragonvalia";
															case "TO":
																return "Toledo";

															default:
																return "Sin Especificar";
														}
												  })()
												: "Sin Especificar"}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateMunicipality(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>

							<div className="form-control col-span-1 md:col-span-2 lg:col-span-3">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Dirección</span>
								</label>
								{updateAddress ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<textarea
													value={address}
													onChange={(e) => onChange(e)}
													name="address"
													type="text"
													className="textarea textarea-bordered resize-none w-full"
													required
													maxLength={250}
												></textarea>
											</span>
											<div className="mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdateAddress(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
											{beneficiary.address}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdateAddress(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>
						</div>

						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Salud
						</div>
						<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
							<div className="form-control col-span-1 md:col-span-2 lg:col-span-3">
								<label htmlFor="document_type" className="label">
									<span className="label-text">Patologías</span>
								</label>
								{updatePathologies ? (
									<>
										<form onSubmit={(e) => onSubmit(e)} className="">
											<span className="flex-grow">
												<textarea
													type="text"
													name="pathologies"
													value={pathologies}
													onChange={(e) => onChange(e)}
													required
													className="textarea textarea-bordered resize-none w-full"
													placeholder="Patologías"
													maxLength={250}
												></textarea>
											</span>
											<div className="mt-3 flex gap-4">
												<button
													type="submit"
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Guardar
												</button>
												<button
													type="button"
													onClick={() => setUpdatePathologies(false)}
													className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
												>
													Cancelar
												</button>
											</div>
										</form>
									</>
								) : (
									<>
										<span className="px-4 py-3 bg-gray-100 rounded-lg w-full">
											{beneficiary.pathologies}
										</span>
										<div className="mt-3">
											<button
												type="button"
												onClick={() => setUpdatePathologies(true)}
												className="btn btn-sm bg-white border border-[#0096C4] text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B] text-xs"
											>
												Modificar
											</button>
										</div>
									</>
								)}
							</div>
							<div className="form-control">
								<label htmlFor="number_queries" className="label">
									<span className="label-text">Numero de Consultas</span>
								</label>
								<span className="w-full px-4 py-3 bg-gray-100 rounded-lg">
									{beneficiary.number_queries}
								</span>
							</div>
						</div>
					</div>
				</>
			) : (
				<div className="w-full h-full flex justify-center items-center">
					<Loading />
				</div>
			)}
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	beneficiary: state.clients.beneficiary,
});
export default connect(mapStateToProps, {
	get_beneficiary,
})(BeneficiarieEdit);
