import React from 'react';
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  StyleSheet,
  View,
} from '@react-pdf/renderer';
import logo from '../../assets/img/logo.png';

const styles = StyleSheet.create({
  body: {
    paddingTop: 5,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: { fontSize: 24, textAlign: 'center', fontFamily: 'Oswald' },
  author: { fontSize: 12, textAlign: 'center', marginBottom: 40 },
  subtitle: { fontSize: 14, fontFamily: 'Oswald' },
  subtitleLatir: { fontSize: 14, fontFamily: 'Oswald', color: '#00496B' },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 150,
    width: 200,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  datosp: {
    fontSize: 13,
    fontFamily: 'Oswald',
    marginTop: 3,
  },
  datosinner: {
    width: '33.33%',
    marginBottom: 6,
  },
  datos: {
    fontSize: 11,
    color: '#676767',
  },
  wrapper: {
    flexDirection: 'col',
    border: '1px solid #676767',
    padding: 6,
    borderRadius: 5,
    marginBottom: 6,
  },
  wrapperLatir: {
    flexDirection: 'col',
    border: '1px solid #676767',
    borderColor: '#00496B',
    padding: 6,
    borderRadius: 5,
    marginBottom: 6,
  },
  content: {
    flexDirection: 'row',
  },
  payed: {
    position: 'absolute',
    top: '90%',
    left: 0,
    color: '#FF0000',
    padding: 8,
    opacity: 0.7,
    fontWeight: 'black',
    textAlign: 'center',
    fontSize: 30,
    width: '170%',
    transform: 'rotate(-45deg)',
    transformOrigin: 'bottom left',
  },
  payedText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

// Register custom font for 'Oswald'
Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

function MyDocument({ data }) {
  let commissionAmountDetails = {};
  try {
    commissionAmountDetails = data && JSON.parse(data.commission_amount);
  } catch (e) {
    console.error("Error al parsear los datos de comisión", e);
  }

  // Detect whether this is a Gestor Comercial commission:
  // user is null, but "gestor_comercial" is in the JSON.
  const isGestorComercial =
    !data?.user && !!commissionAmountDetails?.gestor_comercial;

  return (
    <>
      {data ? (
        <Document title={data.id}>
          <Page style={styles.body} wrap>
            <Image style={styles.image} src={logo} />

            {/* Datos personales */}
            <Text style={styles.subtitle}>Datos personales</Text>
            <View style={styles.wrapper}>
              <View style={styles.content}>
                {/* CASE A: Commission belongs to Gestor Comercial (no user) */}
                {isGestorComercial ? (
                  <>
                    <View style={styles.datosinner}>
                      <Text style={styles.datosp}>Nombres y Apellidos:</Text>
                      <Text style={styles.datos}>
                        {commissionAmountDetails.gestor_comercial?.name}
                      </Text>
                    </View>
                    <View style={styles.datosinner}>
                      <Text style={styles.datosp}>Rol:</Text>
                      <Text style={styles.datos}>Gestor Comercial</Text>
                    </View>
                  </>
                ) : data.user ? (
                  // CASE B: Commission belongs to a "normal" user
                  data.user.role === 'BANCO' ||
                  data.user.role === 'COSTOS Y GASTOS' ||
                  data.user.role === 'IMPUESTOS' ? (
                    <View style={styles.datosinner}>
                      <Text style={styles.datosp}>Rol: </Text>
                      <Text style={styles.datos}>{data.user.role}</Text>
                    </View>
                  ) : (
                    <>
                      <View style={styles.datosinner}>
                        <Text style={styles.datosp}>Nombres y Apellidos:</Text>
                        <Text style={styles.datos}>
                          {data.user.last_name} {data.user.first_name}
                        </Text>
                      </View>
                      <View style={styles.datosinner}>
                        <Text style={styles.datosp}>Rol:</Text>
                        <Text style={styles.datos}>{data.user.role}</Text>
                      </View>
                      <View style={styles.datosinner}>
                        <Text style={styles.datosp}>Documento de Identidad:</Text>
                        <Text style={styles.datos}>
                          {data.user.document_type} - {data.user.nuip}
                        </Text>
                      </View>
                    </>
                  )
                ) : (
                  // CASE C: Neither normal user nor gestor_comercial (fallback)
                  <Text>No se encontraron datos del usuario o gestor</Text>
                )}
              </View>

              <View style={styles.content}>
                {/* Table and Date */}
                <View style={styles.datosinner}>
                  <Text style={styles.datosp}>Mesas asignadas: </Text>
                  <Text style={styles.datos}>
                    {data.table?.table || '—'}
                  </Text>
                </View>
                <View style={styles.datosinner}>
                  <Text style={styles.datosp}>Fecha:</Text>
                  <Text style={styles.datos}>
                    {data.created_at
                      ? new Date(data.created_at).toLocaleDateString()
                      : '—'}
                  </Text>
                </View>
              </View>
            </View>

            {/* Sección de Comisiones */}
            <Text style={styles.subtitle}>Comisiones</Text>
            <View style={styles.wrapper}>
              
              {/* Detalles: niveles */}
              <View style={styles.content}>
                <Text style={[styles.datosp, { marginTop: 6 }]}>
                  Detalles por Nivel
                </Text>
              </View>

              {/* Render all "level_xxx" entries */}
              <View style={styles.content}>
                {data.commission_amount ? (
                  Object.entries(commissionAmountDetails).map(([key, detalles]) => {
                    // Show only the "level_XXX" keys
                    if (!key.toLowerCase().includes('level')) return null;

										// e.g. "level_0" -> "Nivel 1"
										const formattedLevel = key
											.replace(/_/g, ' ')
											.replace(/\b\w/g, (l) => l.toUpperCase())
											.replace('Level', 'Nivel')
											.replace(/\d+/, (num) => parseInt(num) + 1);

                    return (
                      <View style={styles.datosinner} key={key}>
                        <Text style={styles.datosp}>{formattedLevel}:</Text>
                        {/* Monto */}
                        {detalles.monto > 0 && (
                          <Text style={styles.datos}>
                            Monto: $ {detalles.monto}
                          </Text>
                        )}
                        {/* Cantidad de clientes */}
                        {detalles.clientes > 0 && (
                          <Text style={styles.datos}>
                            Clientes: {detalles.clientes}
                          </Text>
                        )}

                        {/* Listado de clientes (nuip, first_name, last_name) */}
                        {Array.isArray(detalles.clients_list) &&
                          detalles.clients_list.length > 0 && (
                            <>
                              <Text style={[styles.datosp, { marginTop: 3 }]}>
                                Clientes en este nivel:
                              </Text>
                              {detalles.clients_list.map((cli, idx) => (
                                <Text style={styles.datos} key={idx}>
                                  - {cli.nuip} {cli.first_name} {cli.last_name}
                                </Text>
                              ))}
                            </>
                          )}
                      </View>
                    );
                  })
                ) : null}
              </View>
								
								{/* Totales */}
								<View style={styles.content}>
                <View style={styles.datosinner}>
                  <Text style={styles.datosp}>Total por pagar</Text>
                  <Text style={styles.datos}>$ {data.total}</Text>
                </View>
                <View style={styles.datosinner}>
                  <Text style={styles.datosp}>Pagado</Text>
                  <Text style={styles.datos}>
                    {data.paid === true ? 'Sí' : 'No'}
                  </Text>
                </View>
              </View>

             
            </View>

            {/* Número de página */}
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />

            {/* Marca de agua "PAGADO" si data.paid === true */}
            {data.paid === true ? (
              <View style={styles.payed}>
                <Text style={styles.payedText}>
                  PAGADO - PAGADO - PAGADO - PAGADO - PAGADO
                </Text>
              </View>
            ) : null}
          </Page>
        </Document>
      ) : (
        "No se pudieron obtener los datos"
      )}
    </>
  );
}

export default MyDocument;
