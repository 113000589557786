import {
	GET_COMMERCIAL_MANAGERS_LIST_SUCCESS,
	GET_COMMERCIAL_MANAGERS_LIST_FAIL,
	GET_COMMERCIAL_MANAGERS_FAIL,
	GET_COMMERCIAL_MANAGERS_SUCCESS,
} from '../actions/commercial-managers/types';

const initialState = {
	commercial_managers: [],
	commercial_managers_list: [],
	count : 0,
	previous : null,
	next : null,
};

export default function commercial_managers(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case GET_COMMERCIAL_MANAGERS_LIST_SUCCESS:
			return {
				...state,
				commercial_managers_list: payload.results,
				count : payload.count,
				previous : payload.previous,
				next : payload.next,
			};
		case GET_COMMERCIAL_MANAGERS_LIST_FAIL:
			return {
				...state,
				commercial_managers_list: [],
			};
		case GET_COMMERCIAL_MANAGERS_SUCCESS:
			return {
				...state,
				commercial_managers: payload,
			};
		case GET_COMMERCIAL_MANAGERS_FAIL:
			return {
				...state
			};
		default:
			return state;
	}
}
