import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IconTrash, IconX } from "@tabler/icons-react";
import { useSelector } from "react-redux";

function DeleteClient({
  data,
  onSubmitDeleteClient,
  type,
  // Estas dos props solo se usan si el padre quiere controlar el modal
  open,      // boolean que indica si está abierto o cerrado
  onClose    // función para cambiar ese estado
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const user = useSelector((state) => state.auth.user);

  // Detectamos si estamos en "modo controlado" o no.
  // Si "open" es boolean y "onClose" es una función, significa que el padre controla el modal.
  const isControlled = typeof open === "boolean" && typeof onClose === "function";

  // Si no estamos en modo controlado, manejamos el estado internamente
  const [internalOpen, setInternalOpen] = useState(false);

  // Decidimos cuál valor de "abierto" usar
  const actualOpen = isControlled ? open : internalOpen;

  // Función para cerrar
  const handleClose = () => {
    if (isControlled) {
      // Si es modo controlado, delegamos al padre
      onClose(false);
    } else {
      // Si es modo interno, actualizamos nuestro state
      setInternalOpen(false);
    }
  };

  // Función para abrir
  const handleOpen = () => {
    if (isControlled) {
      onClose(true);
    } else {
      setInternalOpen(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Cerramos el modal antes de llamar al callback
    handleClose();

    // Llamamos al callback que elimina al cliente
    onSubmitDeleteClient(e, data.id, email, password);
  };

  return (
    <>
      {/* === TRIGGER PARA ABRIR MODAL (solo si NO es titular) === */}
      {type === "titular" ? (
        ""
      ) : (
        // Si no es "titular", mostramos el ícono de la papelera
        <div className="tooltip flex" data-tip="Eliminar">
          <button
            onClick={handleOpen}
            className="p-1 bg-red-500 text-white rounded-full cursor-pointer"
          >
            <IconTrash />
          </button>
        </div>
      )}

      {/* ==== EL MODAL EN SÍ ==== */}
      <Transition.Root show={actualOpen} as={Fragment}>
        {/* IMPORTANTE: al cerrar el Dialog, también usamos handleClose */}
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          {/* Fondo oscuro */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* Posición del modal */}
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all 
                             sm:my-8 sm:w-full sm:max-w-3xl sm:p-6"
                >
                  {/* Botón X para cerrar */}
                  <button
                    className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                    onClick={handleClose}
                  >
                    <IconX size={18} />
                  </button>

                  {/* Título y mensaje */}
                  <div className="text-center mt-2">
                    <Dialog.Title
                      as="h3"
                      className="text-2xl font-medium leading-6 text-gray-900"
                    >
                      {type === "titular"
                        ? "Desactivar Cliente"
                        : "Eliminar Beneficiario"}
                    </Dialog.Title>
                    <div className="mt-2">
                      {type === "titular" ? (
                        <p className="text-xl text-gray-500">
                          ¿Estás seguro de desactivar al cliente <br />
                          <strong>{data.first_name}</strong> (y todos sus beneficiarios)?
                        </p>
                      ) : (
                        <p className="text-xl text-gray-500">
                          ¿Estás seguro de eliminar al beneficiario{" "}
                          <strong>{data.first_name}</strong>?
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Formulario para credenciales (si el user no es "ENLACE") */}
                  {user && user.role !== "ENLACE" ? (
                    <form
                      onSubmit={handleSubmit}
                      className="mt-6 flex flex-col items-center"
                    >
                      <input
                        type="email"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        className="input input-bordered w-full max-w-xs mb-3"
                        required
                      />
                      <input
                        type="password"
                        placeholder="Contraseña"
                        onChange={(e) => setPassword(e.target.value)}
                        className="input input-bordered w-full max-w-xs"
                        required
                      />
                      <div className="mt-4 flex space-x-2">
                        <button
                          type="submit"
                          className="btn border bg-[#c32a2a] text-white"
                        >
                          Eliminar
                        </button>
                        <button
                          type="button"
                          onClick={handleClose}
                          className="btn border bg-[#0096C4] text-white"
                        >
                          Cancelar
                        </button>
                      </div>
                    </form>
                  ) : (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleClose();
                        onSubmitDeleteClient(e, data.id);
                      }}
                      className="mt-6 flex justify-center space-x-2"
                    >
                      <button
                        type="submit"
                        className="btn border bg-[#c32a2a] text-white"
                      >
                        Eliminar
                      </button>
                      <button
                        type="button"
                        onClick={handleClose}
                        className="btn border bg-[#0096C4] text-white"
                      >
                        Cancelar
                      </button>
                    </form>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default DeleteClient;
